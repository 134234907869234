import React from "react";
import { Space, Form, Select, Table, Row, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  sectionExplainers,
  sectionTiers,
  BuildPlanDataType,
  typeExplainers,
  standardSections,
  starterSections,
  phasesExplainers,
} from "../static/DefaultBuildPlan";
import { statusOrder, updateTaskStatus } from "../services/Database";
import { Hero } from "./Hero";
import { SectionCards } from "./SectionCards";
import { Link } from "react-router-dom";

interface BuildingBlocksTableProps {
  buildPlan: BuildPlanDataType[];
  userUID: string;
  subscription: "starter" | "standard" | "supported";
  page: "preview" | "product";
  loading?: boolean;
  paymentPending?: boolean;
}

const createFilter = (
  tasks: BuildPlanDataType[],
  key: "section" | "status"
) => {
  let tempFilter: string[] = [];
  tasks.forEach((t) => {
    if (!tempFilter.includes(t[key])) {
      tempFilter.push(t[key]);
    }
  });
  return tempFilter.map((f) => {
    return {
      text: f,
      value: f,
    };
  });
};

const sectionSortMap = {
  "Building Bedrock 🚀": 1,
  "Standard Success Outcomes 🙌": 2,
  "Onboarding Outline 📈": 3,
  "Automating Activities 🤖": 4,
  "Customer Health Hero 🦸": 5,
  "Geared for Growth 🔥": 6,
};

export const BuildingBlocksTable = ({
  buildPlan,
  userUID,
  subscription,
  loading,
  page,
  paymentPending,
}: BuildingBlocksTableProps) => {
  const productColumns: ColumnsType<BuildPlanDataType> = [
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, record) => (
        <Space size="middle">
          <Form
            initialValues={{
              status: record.status,
            }}
            className="table-cell-min"
          >
            <Form.Item name="status">
              <Select
                onChange={(value: "todo" | "in-progress" | "done") =>
                  updateTaskStatus({
                    userUID: userUID,
                    taskID: record.key,
                    status: value,
                  })
                }
              >
                <Select.Option value="todo">Todo</Select.Option>
                <Select.Option value="in-progress">In Progress</Select.Option>
                <Select.Option value="done">Done</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Space>
      ),
      filters: createFilter(buildPlan, "status"),
      onFilter: (value: string | number | boolean, record) => {
        return record.status === value;
      },
      sorter: (a: BuildPlanDataType, b: BuildPlanDataType) =>
        statusOrder[a.status] - statusOrder[b.status],
      responsive: ["md"],
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Phase",
      dataIndex: "phase",
      key: "phase",
      render: (_, record) => (
        <Space>
          {record.phase}
          <Tooltip title={phasesExplainers[record.phase]}>
            <ExclamationCircleOutlined />
          </Tooltip>
        </Space>
      ),
      responsive: ["md"],
    },
    {
      title: "Building Block",
      dataIndex: "section",
      key: "section",
      render: (_, record) => (
        <Space>
          {record.section}
          <Tooltip title={sectionExplainers[record.section]}>
            <ExclamationCircleOutlined />
          </Tooltip>
        </Space>
      ),
      sorter: (a: BuildPlanDataType, b: BuildPlanDataType) =>
        sectionSortMap[a.section] - sectionSortMap[b.section],
      responsive: ["md"],
    },
    {
      title: "Task Type",
      dataIndex: "taskType",
      key: "task",
      render: (_, record) => (
        <Space>
          {record.taskType}
          <Tooltip title={typeExplainers[record.taskType]}>
            <ExclamationCircleOutlined />
          </Tooltip>
        </Space>
      ),
      responsive: ["md"],
    },
    {
      title: "Notes",
      dataIndex: "key",
      key: "key",
      render: (_, record) => (
        <Space>
          <Link to={`/task/${record.key}`} className="link">
            Notes
          </Link>
        </Space>
      ),
    },
  ];

  const previewColumns: ColumnsType<BuildPlanDataType> = [
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, record) => (
        <Space size="middle">
          <Form
            initialValues={{
              status: record.status,
            }}
            className="table-cell-min"
          >
            <Form.Item name="status">
              <Select
                onChange={(value: "todo" | "in-progress" | "done") =>
                  updateTaskStatus({
                    userUID: userUID,
                    taskID: record.key,
                    status: value,
                  })
                }
              >
                <Select.Option value="todo">Todo</Select.Option>
                <Select.Option value="in-progress">In Progress</Select.Option>
                <Select.Option value="done">Done</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Space>
      ),
      filters: createFilter(buildPlan, "status"),
      onFilter: (value: string | number | boolean, record) => {
        return record.status === value;
      },
      sorter: (a: BuildPlanDataType, b: BuildPlanDataType) =>
        statusOrder[a.status] - statusOrder[b.status],
      responsive: ["md"],
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Phase",
      dataIndex: "phase",
      key: "phase",
      render: (_, record) => (
        <Space>
          {record.phase}
          <Tooltip title={phasesExplainers[record.phase]}>
            <ExclamationCircleOutlined />
          </Tooltip>
        </Space>
      ),
      responsive: ["md"],
    },
    {
      title: "Building Block",
      dataIndex: "section",
      key: "section",
      render: (_, record) => (
        <Space>
          {record.section}
          <Tooltip title={sectionExplainers[record.section]}>
            <ExclamationCircleOutlined />
          </Tooltip>
        </Space>
      ),
      sorter: (a: BuildPlanDataType, b: BuildPlanDataType) =>
        sectionSortMap[a.section] - sectionSortMap[b.section],
      responsive: ["md"],
    },
    {
      title: "Task Type",
      dataIndex: "taskType",
      key: "task",
      render: (_, record) => (
        <Space>
          {record.taskType}
          <Tooltip title={typeExplainers[record.taskType]}>
            <ExclamationCircleOutlined />
          </Tooltip>
        </Space>
      ),
      responsive: ["md"],
    },
    {
      title: "Notes",
      dataIndex: "key",
      key: "key",
      render: (_, record) => (
        <Space>
          <Link to="/signup" className="link">
            Create Account to View Note Templates
          </Link>
        </Space>
      ),
    },
  ];

  const sections =
    subscription === "starter" || paymentPending
      ? starterSections
      : standardSections;

  return (
    <React.Fragment>
      {sections.map((section, i) => {
        return (
          <React.Fragment key={`build-fragment-${i}`}>
            <Row
              key={`build-row-${i}`}
              className="page-header secondary-row-header"
            >
              <Space key={`build-space-${i}`}>
                <h1 key={`build-header-${i}`} className="header">
                  {section}
                </h1>
                <Tooltip
                  title={sectionExplainers[section]}
                  key={`build-tip-${i}`}
                  className="header-secondary"
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </Space>
              <Row className="full-row">
                <Table
                  key={`build-table-${i}`}
                  className="full-row"
                  columns={page === "preview" ? previewColumns : productColumns}
                  dataSource={buildPlan.filter((t: BuildPlanDataType) =>
                    section.includes(t.section)
                  )}
                  loading={
                    loading
                      ? true
                      : subscription === "standard" ||
                        subscription === "supported"
                      ? false
                      : subscription === "starter" &&
                        sectionTiers[section] === "starter"
                      ? false
                      : true
                  }
                  pagination={
                    subscription === "standard" || subscription === "supported"
                      ? { pageSize: 5 }
                      : subscription === "starter" &&
                        sectionTiers[section] === "starter"
                      ? { pageSize: 5 }
                      : { pageSize: 3 }
                  }
                />
              </Row>
            </Row>
          </React.Fragment>
        );
      })}
      {subscription === "starter" ? (
        <React.Fragment>
          <Hero
            key={`build-row-upsell-hero`}
            title="Looking for more ideas?"
            explainer={
              page === "preview"
                ? "Create a Standard Mellasa Account to Use the Efficiency and Enterprise Building Blocks"
                : "Upgrade to Unlock the Efficiency and Enterprise Building Blocks"
            }
            buttons={
              page === "preview"
                ? [
                    {
                      type: "primary",
                      size: "large",
                      text: "Create Account",
                      to: "/signup",
                    },
                  ]
                : [
                    {
                      type: "primary",
                      size: "large",
                      text: "Upgrade",
                      to: "/subscription",
                    },
                  ]
            }
          />
          <SectionCards />
        </React.Fragment>
      ) : undefined}
    </React.Fragment>
  );
};
