import React, { useEffect, useState } from "react";
import { Row, Space, Tag, Tooltip, Button, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { getTask, updateTaskNotes } from "../services/Database";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  sectionExplainers,
  BuildPlanDataType,
  typeExplainers,
  phasesExplainers,
} from "../static/DefaultBuildPlan";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuthListener } from "../services/Authentication";

export const Task = () => {
  const { userUID } = useAuthListener();
  const { id } = useParams();
  const [task, setTask] = useState<BuildPlanDataType>();
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<string>("");
  const navigate = useNavigate();
  const [msg, contextHolder] = message.useMessage();

  useEffect(() => {
    if (userUID && id) {
      getTask({ userUID: userUID, taskUID: id }).then((task) => {
        setTask(task);
        setNotes(task?.notes || "");
        setLoading(false);
      });
    }
  }, [userUID, id]);

  return (
    <React.Fragment>
      {contextHolder}
      {!loading && task && id && (
        <React.Fragment>
          <Row className="full-row-left header">
            <h1>{task?.task}</h1>
          </Row>
          <Row className="full-row-left">
            <Space size={[0, 8]} wrap>
              <Tag
                icon={
                  <Tooltip title={phasesExplainers[task.phase]}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                }
              >
                {task.phase}
              </Tag>
              <Tag
                icon={
                  <Tooltip title={sectionExplainers[task.section]}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                }
              >
                {task.section}
              </Tag>
              <Tag
                icon={
                  <Tooltip title={typeExplainers[task.taskType]}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                }
              >
                {task.taskType}
              </Tag>
            </Space>
          </Row>
          <Row className="full-row-left page-gap">
            <ReactQuill
              theme="snow"
              value={notes}
              onChange={setNotes}
              className="quill"
              modules={{
                clipboard: {
                  matchVisual: false,
                },
              }}
            />
          </Row>
          <Row
            className="full-row-left big-page-gap"
            style={{ marginTop: "10vh" }}
          >
            <Button
              type="primary"
              size="large"
              className="button-set-small"
              onClick={() => {
                updateTaskNotes({
                  userUID: userUID,
                  taskID: id,
                  notes: notes,
                }).then(() => {
                  msg.destroy();
                  msg.success("Task updated");
                });
              }}
            >
              Update Notes
            </Button>
            <Button
              type="primary"
              size="large"
              className="button-set-small"
              onClick={() => {
                updateTaskNotes({
                  userUID: userUID,
                  taskID: id,
                  notes: notes,
                }).then(() => {
                  msg.destroy();
                  msg.success("Task updated");
                  navigate("/tasks");
                });
              }}
            >
              Update Notes and Return to Table View
            </Button>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
