import React from "react";
import { Button, Form, Input, Row, Divider } from "antd";
import {
  googleLogin,
  microsoftLogin,
  signInUserPassword,
  useAuthListener,
} from "../services/Authentication";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Hero } from "../components/Hero";

export const SignIn = () => {
  const { waitingForDB } = useAuthListener();
  const [loginType, setLoginType] = useState<"sso" | "email">("sso");
  const [signInError, setSignInError] = useState<
    boolean | "auth/user-not-found" | "auth/wrong-password"
  >(false);
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    if (values.email && values.password) {
      signInUserPassword({
        email: values.email,
        password: values.password,
      }).catch((error) => {
        if (error === "auth/user-not-found") {
          setSignInError("auth/user-not-found");
        } else if (error === "auth/wrong-password") {
          setSignInError("auth/wrong-password");
        } else {
          setSignInError(true);
        }
      });
      navigate("/tasks");
    }
  };

  const onFinishFailed = (errorFields: any) => {
    console.log(errorFields);
  };

  return (
    <React.Fragment>
      {waitingForDB && (
        <Hero
          title="Your Account Is Being Created"
          explainer="Please click this button to check if it's done."
          buttons={[
            {
              type: "primary",
              size: "large",
              text: "Check",
              to: "/tasks",
            },
          ]}
        />
      )}
      {!!signInError && signInError === "auth/user-not-found" && (
        <Hero
          title="Account Not Found"
          explainer="Looks like your email is not associated with a Mellasa account. Please Sign Up instead."
          buttons={[
            {
              type: "primary",
              size: "large",
              text: "Sign Up",
              to: "/signup",
            },
          ]}
        />
      )}
      {!!signInError && signInError === "auth/wrong-password" && (
        <Hero
          title="Email and Password Failed"
          explainer="Please try logging in again."
        />
      )}
      {!!signInError &&
        signInError !== "auth/user-not-found" &&
        signInError !== "auth/wrong-password" && (
          <Hero
            title="Our Bad"
            explainer="Something went wrong when you tried to sign in. Please try again and email support@mellasa.com if this issue persists."
            buttons={[
              {
                type: "primary",
                size: "large",
                text: "Try Again",
                to: "/signin",
              },
            ]}
          />
        )}
      {!!!signInError && (
        <React.Fragment>
          <Hero title="Sign In to Mellasa" /> <Divider />
          <Row className="full-row page-header" style={{ minHeight: "20vh" }}>
            <Button
              onClick={() => {
                googleLogin().finally(() => {
                  navigate("/tasks");
                });
              }}
              className="button-set"
              size="large"
            >
              Sign In with Google
            </Button>
            <Button
              onClick={() => {
                microsoftLogin().finally(() => {
                  navigate("/tasks");
                });
              }}
              className="button-set"
              size="large"
            >
              Sign In with Microsoft
            </Button>
            {loginType === "sso" ? (
              <Button onClick={() => setLoginType("email")} size="large">
                Sign In with Email
              </Button>
            ) : undefined}
          </Row>
        </React.Fragment>
      )}
      {loginType === "email" ? (
        <Row className="full-row page-header">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  min: 12,
                  message: "Minimum 12 characters",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit">Sign In</Button>
            </Form.Item>
          </Form>
        </Row>
      ) : undefined}
    </React.Fragment>
  );
};
