import React from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  List,
  Divider,
  message,
  Timeline,
} from "antd";
import {
  googleLogin,
  microsoftLogin,
  createUserPassword,
  SubscriptionType,
  subscriptions,
  useAuthListener,
} from "../services/Authentication";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  starterTier,
  starterTierOutcome,
  standardTier,
  standardTierOutcome,
  supportedTier,
  supportedTierOutcome,
} from "../static/Tiers";
import { Hero } from "../components/Hero";

export const SignUp = () => {
  const { waitingForDB } = useAuthListener();
  const [loginType, setLoginType] = useState<"sso" | "email">("sso");
  const [signUpError, setSignUpError] = useState(false);
  const [subscription, setTier] = useState<SubscriptionType>("starter");
  const navigate = useNavigate();
  const [msg, contextHolder] = message.useMessage();

  const onFinish = (values: any) => {
    if (values.email && values.password) {
      createUserPassword({
        email: values.email,
        password: values.password,
        subscription: subscription as SubscriptionType,
      })
        .then(() => {
          navigate("/initializing");
        })
        .catch((error) => {
          console.log(error);
          setSignUpError(true);
        });
    }
  };

  const onFinishFailed = (errorFields: any) => {
    console.log(errorFields);
  };

  return (
    <React.Fragment>
      {contextHolder}
      {waitingForDB && (
        <Hero
          title="Your Account Is Being Created"
          explainer="Please click this button to check if it's done."
          buttons={[
            {
              type: "primary",
              size: "large",
              text: "Check",
              to: "/tasks",
            },
          ]}
        />
      )}
      {!!!signUpError && (
        <React.Fragment>
          <Hero
            title="Create Mellasa Account"
            explainer="Costs less than churning a single customer"
          />
          <Divider />
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 24 }}
            className="full-row-tall"
            align="stretch"
          >
            {subscriptions.map((t: SubscriptionType, i) => {
              return (
                <Col
                  key={`sign-up-subscriptions-${i}`}
                  xs={20}
                  sm={20}
                  md={20}
                  lg={4}
                  xl={4}
                  className="flex card-margin"
                >
                  <Card
                    title={
                      t ? t?.charAt(0).toUpperCase() + t?.slice(1) : undefined
                    }
                    onClick={() => {
                      if (t === "starter" || t === "standard") {
                        setTier(t);
                      } else {
                        msg.destroy();
                        msg.warning(
                          "The Supported subscription is not yet available. Email hey@mellasa.com to join the waitlist.",
                          3
                        );
                      }
                    }}
                    hoverable={
                      t === "starter" || t === "standard" ? true : false
                    }
                    className={t === subscription ? "active-bg" : undefined}
                  >
                    <List
                      dataSource={
                        t === "starter"
                          ? starterTier
                          : t === "standard"
                          ? standardTier
                          : supportedTier
                      }
                      renderItem={(item) => <List.Item>{item}</List.Item>}
                      className="lefty"
                    />
                    <Divider />
                    <p>Expectations</p>
                    <Timeline
                      items={
                        t === "starter"
                          ? starterTierOutcome
                          : t === "standard"
                          ? standardTierOutcome
                          : supportedTierOutcome
                      }
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row className="full-row page-gap">
            <Button
              onClick={() => {
                googleLogin(subscription as SubscriptionType)
                  .then((resp) => {
                    navigate("/initializing");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              className="button-set"
            >
              Sign Up with Google
            </Button>
            <Button
              onClick={() => {
                microsoftLogin(subscription as SubscriptionType)
                  .then(() => {
                    navigate("/initializing");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              className="button-set"
            >
              Sign Up with Microsoft
            </Button>
            {loginType === "sso" ? (
              <Button
                onClick={() => setLoginType("email")}
                className="button-set"
              >
                Sign Up with Email
              </Button>
            ) : undefined}
          </Row>
          {loginType === "email" ? (
            <Row className="full-row page-gap">
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      min: 12,
                      message: "Minimum 12 characters",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit">Sign Up with Email</Button>
                </Form.Item>
              </Form>
            </Row>
          ) : undefined}
        </React.Fragment>
      )}
      {!!signUpError && (
        <Hero
          title="Our Bad"
          explainer="Something went wrong when you tried to sign up. Please try again and email support@mellasa.com if this issue persists."
          buttons={[
            {
              type: "primary",
              size: "large",
              text: "Try Again",
              to: "/signup",
            },
          ]}
        />
      )}
      <Divider />
      <Row className="full-row page-header footer">
        <Hero
          title="Already have an account?"
          buttons={[
            {
              type: "primary",
              size: "large",
              text: "Sign In",
              to: "/signin",
            },
          ]}
        />
      </Row>
    </React.Fragment>
  );
};
