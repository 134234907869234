import React from "react";
import { Row, Col, Button, Space } from "antd";
import { Link } from "react-router-dom";

interface ButtonProps {
  type:
    | "link"
    | "text"
    | "ghost"
    | "default"
    | "primary"
    | "dashed"
    | undefined;
  size: "large" | "middle" | "small";
  text: string;
  to: string | undefined;
  target?: string;
  download?: string;
}

interface HeroProps {
  title?: string;
  explainer?: string;
  buttons?: ButtonProps[];
  secondary?: boolean;
}

export const Hero = ({ title, explainer, buttons, secondary }: HeroProps) => {
  return (
    <React.Fragment>
      <Row className="page-header full-row">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <Space direction="vertical">
            {title ? (
              <Row className="full-row">
                <h1 className="hero-title">{title}</h1>
              </Row>
            ) : undefined}
            {explainer ? (
              <Row className="full-row">
                <p className="hero-explainer">{explainer}</p>
              </Row>
            ) : undefined}
            {buttons && (
              <Row className="full-row">
                {buttons &&
                  buttons.map((b, i) => {
                    if (b?.to) {
                      return (
                        <Button
                          key={`hero-button-${i}`}
                          type={b.type}
                          size={b.size}
                          className="button-set"
                        >
                          <Link
                            to={b.to}
                            target={b.target ? b.target : undefined}
                            download={b.download ? b.download : undefined}
                          >
                            {b.text}
                          </Link>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          type={b.type}
                          size={b.size}
                          className="button-set"
                        >
                          {b.text}
                        </Button>
                      );
                    }
                  })}
              </Row>
            )}
          </Space>
        </Col>
      </Row>
    </React.Fragment>
  );
};
