import "./App.css";
import { ConfigProvider, theme } from "antd";
import { SignUp } from "./pages/SignUp";
import { SignIn } from "./pages/SignIn";
import { Tasks } from "./pages/Tasks";
import { Task } from "./pages/Task";
import { Account } from "./pages/Account";
import { Reporting } from "./pages/Reporting";
import { Verify } from "./pages/Verify";
import { BuildPreview } from "./pages/BuildPreview";
import { InitializingAccount } from "./pages/InitializingAccount";
import { Layout } from "./pages/Layout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuthListener } from "./services/Authentication";
import { PageNotFound } from "./pages/PageNotFound";
import { Subscription } from "./pages/Subscription";
import { HomeLogin } from "./pages/HomeSignIn";

function App() {
  const { authenticated, checkingAuthentication, verified, user } =
    useAuthListener();

  const ProtectedRoute = ({ children }: any) => {
    return !checkingAuthentication && authenticated && verified ? (
      children
    ) : authenticated && user !== null ? (
      <Verify />
    ) : (
      <Navigate to={"/signin"} />
    );
  };

  const SkipIfAuthenticatedRoute = ({ children }: any) => {
    return !checkingAuthentication && authenticated ? (
      <Navigate to={"/initializing"} />
    ) : (
      children
    );
  };

  return (
    <ConfigProvider
      // for future updates: https://ant.design/docs/react/customize-theme#seedtoken
      // colors: https://ant.design/docs/spec/dark#color-palette
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorLink: "#301c4d",
          colorPrimary: "#51258f",
          colorTextBase: "white",
        },
      }}
    >
      {!!!checkingAuthentication && (
        <Router>
          <Layout authenticated={authenticated}>
            <Routes>
              <Route
                path="/"
                element={
                  <SkipIfAuthenticatedRoute>
                    <HomeLogin />
                  </SkipIfAuthenticatedRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  <SkipIfAuthenticatedRoute>
                    <SignUp />
                  </SkipIfAuthenticatedRoute>
                }
              />
              <Route
                path="/signin"
                element={
                  <SkipIfAuthenticatedRoute>
                    <SignIn />
                  </SkipIfAuthenticatedRoute>
                }
              />
              <Route path="/preview" element={<BuildPreview />} />
              <Route
                path="/reporting"
                element={
                  <ProtectedRoute>
                    <Reporting />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tasks"
                element={
                  <ProtectedRoute>
                    <Tasks />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/task/:id"
                element={
                  <ProtectedRoute>
                    <Task />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/subscription"
                element={
                  <ProtectedRoute>
                    <Subscription />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <Account />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/initializing"
                element={
                  <ProtectedRoute>
                    <InitializingAccount />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Layout>
        </Router>
      )}
    </ConfigProvider>
  );
}

export default App;
