import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  getFirestore,
  collection,
  setDoc,
  getDoc,
  doc,
  where,
  getDocs,
  query,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { UserCredential } from "firebase/auth";
import { ChartShallowDataShape } from "reaviz";
import { app } from "./Firebase";
import {
  BuildPlanDataType,
  standardSections,
} from "../static/DefaultBuildPlan";
import { SubscriptionType } from "./Authentication";

const db = getFirestore(app);

export type User = {
  userUID: string;
  email: string;
};

export const addUpdateUser = async (
  auth: UserCredential,
  subscription?: SubscriptionType
): Promise<any> => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "users", auth.user.uid)).then((user) => {
      if (!!!user.exists()) {
        if (auth.user.email) {
          setDoc(
            doc(db, "users", auth.user.uid),
            {
              email: auth.user.email,
              name: auth.user.displayName,
              lastLogin: serverTimestamp(),
              subscription: subscription ? subscription : "starter",
              accountUID: auth.user.uid,
              userUID: auth.user.uid,
            },
            { merge: true }
          )
            .then(() => {
              getSelf(auth.user.uid).then((self) => {
                createAccount({
                  userUID: self.userUID,
                  userEmail: self.email,
                  subscription: self.subscription,
                })
                  .then(() => {
                    getDefaultTasks({ subscription: self.subscription })
                      .then((tasks) => {
                        batchCreateTasks({
                          tasks: tasks,
                          userUID: self.userUID,
                          accountUID: self.accountUID,
                        })
                          .then(() => {
                            resolve(self);
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => console.log(error));
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        setDoc(
          doc(db, "users", auth.user.uid),
          {
            lastLogin: serverTimestamp(),
          },
          { merge: true }
        )
          .then(() => {
            getSelf(auth.user.uid).then((self) => {
              resolve(self);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  });
};

export type UserRoles = "viewer" | "user" | "admin";
export type UserRolesObject = { [key: string]: UserRoles };

export type AccountType = {
  invited: string[];
  name: string;
  ownerEmail: string;
  ownerUID: string;
  subscription: SubscriptionType;
  userList: string[];
  userRoles: UserRolesObject;
};

interface CreateAccountProps {
  userUID: string;
  userEmail: string;
  subscription: SubscriptionType;
}

export const createAccount = async ({
  userUID,
  userEmail,
  subscription,
}: CreateAccountProps): Promise<any> => {
  return new Promise((resolve, reject) => {
    getAccountbyUID(userUID)
      .then((account) => {
        if (account !== undefined) {
          resolve(account);
        } else {
          let tempRoles: any = {};
          tempRoles[userEmail] = "admin";
          setDoc(doc(db, "accounts", userUID), {
            invited: [],
            name: userEmail,
            ownerEmail: userEmail,
            paymentPending: subscription === "starter" ? false : true,
            subscription: subscription,
            userList: [userEmail],
            userRoles: tempRoles,
          })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

interface InviteUserToAccountProps {
  accountUID: string;
  userRole: UserRoles;
  userEmail: string;
}

export const checkForAccountInvite = async (
  userEmail: string
): Promise<any> => {
  return new Promise(function (resolve, reject) {
    if (userEmail) {
      getDocs(
        query(
          collection(db, "accounts"),
          where("invited", "array-contains", userEmail)
        )
      )
        .then((docs) => {
          if (docs.size > 0) {
            docs.forEach((doc) => {
              let tempAccount: any = doc.data();
              tempAccount["accountUID"] = doc.id;
              resolve(tempAccount);
            });
          } else {
            reject("No invite found");
          }
        })
        .catch((error) => {
          console.log(error);
          reject("No invite found");
        });
    } else {
      reject("No invite found");
    }
  });
};

interface InviteResponseProps {
  accountUID: string;
  accept: boolean;
  userEmail: string;
  userUID: string;
}

export const inviteResponse = async ({
  accountUID,
  accept,
  userEmail,
  userUID,
}: InviteResponseProps): Promise<any> => {
  return new Promise(function (resolve, reject) {
    getAccountbyUID(accountUID).then((account: AccountType) => {
      let currentAccount = account;
      currentAccount.invited = currentAccount.invited.filter(
        (u: string) => u !== userEmail
      );
      if (!accept) {
        currentAccount.userList = currentAccount.userList.filter(
          (u: string) => u !== userEmail
        );
        delete currentAccount.userRoles[userEmail];
      }
      setDoc(
        doc(db, "accounts", accountUID),
        {
          invited: currentAccount.invited,
          userList: currentAccount.userList,
          userRoles: currentAccount.userRoles,
        },
        { merge: true }
      )
        .then(() => {
          if (accept) {
            updateAccountUID({
              userUID: userUID,
              accountUID: accountUID,
              subscription: currentAccount.subscription
                ? currentAccount.subscription
                : "starter",
            })
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  });
};

export const inviteUserToAccount = async ({
  accountUID,
  userRole,
  userEmail,
}: InviteUserToAccountProps): Promise<any> => {
  return new Promise(function (resolve, reject) {
    getAccountbyUID(accountUID).then((account: AccountType) => {
      let currentAccount = account;
      currentAccount.userRoles[userEmail] = userRole;
      currentAccount.invited.push(userEmail);
      currentAccount.userList.push(userEmail);
      setDoc(doc(db, "accounts", accountUID), currentAccount, { merge: true })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  });
};

export const getAccountbyUID = async (accountUID: string): Promise<any> => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "accounts", accountUID))
      .then((doc) => {
        if (doc.exists()) {
          resolve(doc.data());
        } else {
          resolve(undefined);
        }
      })
      .catch((error) => {
        console.log(error);
        reject("No data found");
      });
  });
};

export const subscriptions: SubscriptionType[] = ["standard", "supported"];

interface UpdateSubscriptionProps {
  accountUID: string;
  subscription: SubscriptionType;
  squareBillingContact: string;
  squareCustomerId: string;
  squareOrderId: string;
  squareInvoiceId: string;
  squarePublicUrl: string | undefined;
}

export const updateSubscription = async ({
  accountUID,
  subscription,
  squareBillingContact,
  squareCustomerId,
  squareOrderId,
  squareInvoiceId,
  squarePublicUrl,
}: UpdateSubscriptionProps): Promise<any> => {
  return new Promise(function (resolve, reject) {
    setDoc(
      doc(db, "accounts", accountUID),
      {
        subscription: subscription,
        paymentPending: subscription === "starter" ? false : true,
        squareBillingContact: squareBillingContact,
        squareCustomerId: squareCustomerId,
        squareOrderId: squareOrderId,
        squareInvoiceId: squareInvoiceId,
        squarePublicUrl: squarePublicUrl === undefined ? "" : squarePublicUrl,
      },
      { merge: true }
    )
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const updateInvoiceStatus = async ({
  accountUID,
  squareInvoiceStatus,
}: {
  accountUID: string;
  squareInvoiceStatus: string;
}): Promise<any> => {
  return new Promise(function (resolve, reject) {
    setDoc(
      doc(db, "accounts", accountUID),
      {
        paymentPending: squareInvoiceStatus === "PAID" ? false : true,
      },
      { merge: true }
    )
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const updateInvoicePublicUrl = async ({
  accountUID,
  squarePublicUrl,
}: {
  accountUID: string;
  squarePublicUrl: string;
}): Promise<any> => {
  return new Promise(function (resolve, reject) {
    setDoc(
      doc(db, "accounts", accountUID),
      {
        squarePublicUrl: squarePublicUrl,
      },
      { merge: true }
    )
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

interface UpdateAccountUIDProps {
  userUID: string;
  accountUID: string;
  subscription: SubscriptionType;
}

export const updateAccountUID = async ({
  userUID,
  accountUID,
  subscription,
}: UpdateAccountUIDProps): Promise<any> => {
  return new Promise(function (resolve, reject) {
    setDoc(
      doc(db, "users", userUID),
      {
        accountUID: accountUID,
        subscription: subscription,
      },
      { merge: true }
    )
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const updateAccountSubscription = async ({
  accountUID,
  subscription,
}: {
  accountUID: string;
  subscription: SubscriptionType;
}): Promise<any> => {
  return new Promise(function (resolve, reject) {
    setDoc(
      doc(db, "accounts", accountUID),
      {
        subscription: subscription,
      },
      { merge: true }
    )
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

export const getSelf = (userUID: string): Promise<User | any> => {
  return new Promise(function (resolve, reject) {
    getDoc(doc(db, "users", userUID))
      .then((doc) => {
        if (doc.exists()) {
          resolve(doc.data());
        } else {
          reject("No data available");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

interface BatchTaskUpdate {
  tasks: BuildPlanDataType[];
  userUID: string;
  accountUID: string;
}

export const batchCreateTasks = ({
  tasks,
  userUID,
  accountUID,
}: BatchTaskUpdate): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const table =
      userUID === "2BeYYRBe9uOSgKuy2IhPcHGg6cH3" ? "default" : "tasks";
    const batch = writeBatch(db);
    tasks.forEach((task) => {
      task.userUID = userUID;
      task.accountUID = accountUID;
      const docRef = doc(db, table, uuidv4());
      batch.set(docRef, task);
    });
    batch
      .commit()
      .then(() => resolve(true))
      .catch((error) => {
        console.log(error);
      });
  });
};

type TaskStatusUpdate = {
  userUID: string;
  taskID: string;
  status: "todo" | "in-progress" | "done";
};

export const updateTaskStatus = ({
  userUID,
  taskID,
  status,
}: TaskStatusUpdate): Promise<any> => {
  return new Promise(function (resolve, reject) {
    const table =
      userUID === "2BeYYRBe9uOSgKuy2IhPcHGg6cH3" ? "default" : "tasks";
    setDoc(
      doc(db, table, taskID),
      {
        status: status,
      },
      { merge: true }
    )
      .then(() => resolve(taskID))
      .catch((error) => reject(error));
  });
};

type TaskNotesUpdate = {
  userUID: string;
  taskID: string;
  notes: string;
};

export const updateTaskNotes = ({
  userUID,
  taskID,
  notes,
}: TaskNotesUpdate): Promise<any> => {
  return new Promise(function (resolve, reject) {
    const table =
      userUID === "2BeYYRBe9uOSgKuy2IhPcHGg6cH3" ? "default" : "tasks";
    setDoc(
      doc(db, table, taskID),
      {
        notes: notes,
      },
      { merge: true }
    )
      .then(() => resolve(taskID))
      .catch((error) => reject(error));
  });
};

export const getTasks = async ({
  userUID,
  accountUID,
}: {
  userUID: string;
  accountUID: string;
}): Promise<any> => {
  return new Promise(function (resolve) {
    const table =
      userUID === "2BeYYRBe9uOSgKuy2IhPcHGg6cH3" ? "default" : "tasks";
    if (userUID !== undefined && accountUID !== undefined) {
      getDocs(
        query(collection(db, table), where("accountUID", "==", accountUID))
      )
        .then((docs) => {
          if (docs.size > 0) {
            let temp: any = [];
            docs.forEach((doc) => {
              let tempDoc: any = doc.data();
              tempDoc.key = doc.id;
              temp.push(tempDoc);
            });
            resolve(temp);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.log(error);
          resolve([]);
        });
    }
  });
};

interface GetTaskProps {
  userUID: string;
  taskUID: string;
}

export const getTask = async ({
  userUID,
  taskUID,
}: GetTaskProps): Promise<any> => {
  return new Promise(function (resolve, reject) {
    const table =
      userUID === "2BeYYRBe9uOSgKuy2IhPcHGg6cH3" ? "default" : "tasks";
    getDoc(doc(db, table, taskUID))
      .then((doc) => {
        if (doc.exists()) {
          resolve(doc.data());
        } else {
          reject("No data available");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDefaultTasks = ({
  subscription,
}: {
  subscription: string | undefined;
}): Promise<any> => {
  return new Promise(function (resolve) {
    if (subscription !== undefined) {
      getDocs(
        query(
          collection(db, "default"),
          where("subscription", "in", ["starter", subscription])
        )
      ).then((docs) => {
        if (docs.size > 0) {
          let temp: any = [];
          docs.forEach((doc) => {
            let tempDoc: any = doc.data();
            tempDoc.key = doc.id;
            temp.push(tempDoc);
          });
          resolve(temp);
        } else {
          resolve([]);
        }
      });
    } else {
      resolve([]);
    }
  });
};

export const getUpgradeTasks = ({
  sections,
}: {
  sections: string[];
}): Promise<any> => {
  return new Promise(function (resolve) {
    if (sections.length > 0) {
      getDocs(
        query(collection(db, "default"), where("section", "in", sections))
      ).then((docs) => {
        if (docs.size > 0) {
          let temp: any = [];
          docs.forEach((doc) => {
            let tempDoc: any = doc.data();
            tempDoc.key = doc.id;
            temp.push(tempDoc);
          });
          resolve(temp);
        } else {
          resolve([]);
        }
      });
    } else {
      resolve([]);
    }
  });
};

interface PhaseGraphData extends ChartShallowDataShape {
  phase: string;
}

interface SectionGraphData extends ChartShallowDataShape {
  section: string;
}

export const statusOrder: any = {
  todo: 1,
  Todo: 1,
  "in-progress": 2,
  "In Progress": 2,
  done: 3,
  Done: 3,
};

export const useTaskHistory = ({
  userUID,
  accountUID,
}: {
  userUID: string;
  accountUID: string;
}) => {
  const [tasks, setTasks] = useState<BuildPlanDataType[]>();
  const [taskCount, setTaskCount] = useState(undefined);
  const [taskCompletedCount, setTaskCompletedCount] = useState(undefined);
  const [phasesComplete, setPhasesComplete] = useState(0);
  const [buildingBlocksComplete, setPillarsComplete] = useState(0);
  const [taskPhaseCounts, setTaskPhaseCounts] = useState<PhaseGraphData[]>([]);
  const [taskSectionCounts, setTaskSectionCounts] = useState<
    SectionGraphData[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [refreshTasks, setRefreshTasks] = useState(false);

  useEffect(() => {
    setLoading(true);
    setRefreshTasks(false);
    if (accountUID !== undefined && userUID !== undefined) {
      getTasks({
        accountUID: accountUID,
        userUID: userUID,
      })
        .then((tasks) => {
          tasks.forEach((task: BuildPlanDataType) => {
            if (task.status === "todo" || task.status === "Todo") {
              task.status = "Todo";
            } else if (
              task.status === "in-progress" ||
              task.status === "In Progress"
            ) {
              task.status = "In Progress";
            } else {
              task.status = "Done";
            }
          });
          setTasks(
            tasks.sort((a: BuildPlanDataType, b: BuildPlanDataType) =>
              a?.order && b?.order ? a?.order - b?.order : undefined
            )
          );
          setTaskCount(tasks.length);
          setTaskCompletedCount(
            tasks.filter(
              (t: BuildPlanDataType) =>
                t.status === "done" || t.status === "Done"
            ).length
          );

          // sections completed
          let tempPillarsComplete: number = 0;
          standardSections.forEach((section) => {
            if (
              tasks.filter(
                (t: any) => t.section === section.slice(0, section.length - 3)
              ).length ===
              tasks.filter(
                (t: any) =>
                  t.section === section.slice(0, section.length - 3) &&
                  t.status === "Done"
              ).length
            ) {
              tempPillarsComplete = tempPillarsComplete + 1;
            }
          });
          setPillarsComplete(tempPillarsComplete);

          // phases completed
          let tempPhasesDone: number = 0;
          ["Essentials", "Efficiency", "Enterprise"].forEach((phase) => {
            if (
              tasks.filter((t: any) => t.phase === phase).length ===
              tasks.filter((t: any) => t.phase === phase && t.status === "Done")
                .length
            ) {
              tempPhasesDone = tempPhasesDone + 1;
            }
          });
          setPhasesComplete(tempPhasesDone);

          // search graph data store
          let taskGraphData: any = {
            phaseStatusCounts: {},
            sectionStatusCounts: {},
          };

          // phases graph data
          tasks.forEach((t: BuildPlanDataType) => {
            if (taskGraphData["phaseStatusCounts"][t.phase] === undefined) {
              taskGraphData["phaseStatusCounts"][t.phase] = {};
            }

            if (
              taskGraphData["phaseStatusCounts"][t.phase][t.status] !==
              undefined
            ) {
              taskGraphData["phaseStatusCounts"][t.phase][t.status] += 1;
            } else {
              taskGraphData["phaseStatusCounts"][t.phase][t.status] = 1;
            }
          });

          let phaseGraphData = [];
          for (const x in taskGraphData.phaseStatusCounts) {
            for (const y in taskGraphData.phaseStatusCounts[x]) {
              phaseGraphData.push({
                phase: x,
                key: y,
                data: taskGraphData.phaseStatusCounts[x][y],
              });
            }
          }
          setTaskPhaseCounts(
            phaseGraphData.sort(
              (a: any, b: any) => statusOrder[a.key] - statusOrder[b.key]
            )
          );

          // sections graph data
          tasks.forEach((t: BuildPlanDataType) => {
            if (taskGraphData["sectionStatusCounts"][t.section] === undefined) {
              taskGraphData["sectionStatusCounts"][t.section] = {};
            }

            if (
              taskGraphData["sectionStatusCounts"][t.section][t.status] !==
              undefined
            ) {
              taskGraphData["sectionStatusCounts"][t.section][t.status] += 1;
            } else {
              taskGraphData["sectionStatusCounts"][t.section][t.status] = 1;
            }
          });
          let sectionsGraphData = [];
          for (const x in taskGraphData.sectionStatusCounts) {
            for (const y in taskGraphData.sectionStatusCounts[x]) {
              sectionsGraphData.push({
                section: x,
                key: y,
                data: taskGraphData.sectionStatusCounts[x][y],
              });
            }
          }
          setTaskSectionCounts(sectionsGraphData);
          setLoading(false);
        })
        .catch((error) => {
          setTasks(undefined);
          console.log(error);
        });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [setTasks, refreshTasks, userUID, accountUID, setRefreshTasks, loading]);

  return {
    tasks,
    taskCount,
    taskCompletedCount,
    taskPhaseCounts,
    taskSectionCounts,
    buildingBlocksComplete,
    phasesComplete,
    loading,
    refreshTasks,
    setLoading,
    setRefreshTasks,
  };
};
