export const randomStatusUpdate = () => {
  const options: string[] = ["todo", "in-progress", "done"];
  return options[Math.floor(Math.random() * options.length)];
};

export interface BuildPlanDataType {
  userUID?: string;
  accountUID?: string | undefined;
  order?: number;
  key: string;
  subscription: "starter" | "standard";
  phase: "Essentials" | "Efficiency" | "Enterprise";
  section:
    | "Building Bedrock 🚀"
    | "Standard Success Outcomes 🙌"
    | "Onboarding Outline 📈"
    | "Automating Activities 🤖"
    | "Customer Health Hero 🦸"
    | "Geared for Growth 🔥";
  taskType: "Research" | "Documentation" | "Action";
  task: string;
  status: "todo" | "in-progress" | "done" | "Todo" | "In Progress" | "Done";
  notes?: string;
}

export const defaultBuildPlan: BuildPlanDataType[] = [
  {
    notes:
      "<h3>Background</h3><p><br></p><p>It is important to understand your product messaging in order to support your customers. One factor in this is public facing demo content.</p><p><br></p><h3>Questions</h3><p><br></p><ol><li>What are the challenges that we solve?</li><li>What are the top pages shown in our product?</li><li>What are the key features that are shown off?</li><li>Where could someone new to the product easily get confused?</li></ol>",
    taskType: "Research",
    section: "Building Bedrock 🚀",
    phase: "Essentials",
    order: 1,
    status: "Todo",
    subscription: "starter",
    key: "7213fedb-4e16-4eb2-bdfe-4b61802059f7",
    task: "Watch all public facing demos of your product or sit in on a few live demos",
  },
  {
    task: "Learn the core use cases of your product and how customers are accomplishing them",
    status: "Todo",
    key: "ede728b9-b18a-4550-a68d-3cf2580e8756",
    order: 2,
    section: "Building Bedrock 🚀",
    taskType: "Research",
    phase: "Essentials",
    notes:
      "<h3>Background</h3><p><br></p><p>There could be a difference in how your marketing displays your product and the true value your customers get from it. This is common since often times we think about features and customers think about use cases. This exercise will help you think like a customer.</p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>How is our largest customer using our product?</li><li>How is our most engaged customer using our product?</li><li>What are the business outcomes our customers are getting from our product?</li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Set of 3-5 use cases for your product that you see customers accomplishing</li><li>Sets of steps for each use case to accomplish them</li></ol>",
    subscription: "starter",
  },
  {
    section: "Building Bedrock 🚀",
    key: "0a72e54b-029f-4a1b-9968-aa82776ec341",
    subscription: "starter",
    task: "Read through all of the customer facing documentation of your product and try everything along the way",
    taskType: "Research",
    order: 3,
    status: "Todo",
    notes:
      "<h3>Background</h3><p><br></p><p>This exercise helps you put yourself in your customer's shoes. It's critical for learning what it's like to actually be a customer and use your product.</p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What did I have trouble doing?</li><li>What documentation are we missing completely?</li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>List of action items to improve your documentation</li><li>Complete understanding of where your customer pain points will land</li></ol>",
    phase: "Essentials",
  },
  {
    task: "Click every button, knob, etc in your product’s UI and see what happens",
    key: "922823e2-74d4-4b7d-820c-b34fa849d35f",
    order: 4,
    subscription: "starter",
    taskType: "Research",
    section: "Building Bedrock 🚀",
    notes:
      "<h3>Background</h3><p><br></p><p>This exercise helps you put yourself in your customer's shoes (again). It will help you identify edge cases in your product and how they could be leveraged. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What did I have trouble doing?</li><li>What is high value that would be easy to miss? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>List of challenges your customers will face and how to make them easier </li><li>Ideas for new documentation and playbooks for customers to follow </li></ol>",
    status: "Todo",
    phase: "Essentials",
  },
  {
    section: "Building Bedrock 🚀",
    phase: "Essentials",
    status: "Todo",
    task: "If you don’t understand something, ask your product team to help you understand",
    key: "1db46afd-3f74-49a9-887b-51bb82bbbd3c",
    taskType: "Research",
    subscription: "starter",
    order: 5,
    notes:
      "<h3>Background</h3><p><br></p><p>This is more of a reminder than exercise. As you are working through the Building Bedrock 🚀, you will not be an expert on everything. Use your team to help you along the way. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What is confusing you? </li><li>Who do you need help from? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Better relationships with your internal teams </li><li>Better understanding of who has all the tribal knowledge you need to surface to your team and customers </li></ol>",
  },
  {
    section: "Building Bedrock 🚀",
    key: "a40b46d9-f94c-4b51-a0f4-2c60c879a292",
    taskType: "Research",
    order: 6,
    task: "Read all the internal documentation your product team has built related to your product",
    subscription: "starter",
    phase: "Essentials",
    notes:
      "<h3>Background</h3><p><br></p><p>It's likely that not ALL of your product documentation is customer facing. You need to review your internal wiki as well to surface hidden gems.</p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What could be made public?</li><li>What could be made into templates for future conversations with customers?</li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Ideas for new documentation and templates</li></ol>",
    status: "Todo",
  },
  {
    task: "Try to understand what your infrastructure looks like behind the scenes",
    notes:
      "<h3>Background</h3><p><br></p><p>While this may be out of scope for less technical folks, it's helpful to understand what your product architecture looks like. This could include a variety of things depending on the complexity of your product. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>How is your product hosted? </li><li>How do deployments work? </li><li>What are the key things customers can configure? </li><li>What breaks the most? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Better documentation on your product architecture </li></ol>",
    key: "e4f68504-6a1a-44e4-9957-1ffc04d8198f",
    section: "Building Bedrock 🚀",
    status: "Todo",
    taskType: "Research",
    subscription: "starter",
    phase: "Essentials",
    order: 7,
  },
  {
    section: "Building Bedrock 🚀",
    status: "Todo",
    subscription: "starter",
    notes:
      "<h3>Background</h3><p><br></p><p>While it won't be every customer, your most advanced customers will likely use your API to do things you don't support natively. It's helpful to better understand how it works. </p><h3><br></h3><h3>Discovery Questions</h3><p><br></p><ol><li>How does authentication work for your API? </li><li>Are any customers automating things with your API today? </li><li>Are there any open feature requests that could be accomplished with the API? </li><li>If capable, can you create a set of sample scripts for your customers to use? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Understanding of key use cases for your API</li><li>Understanding of the limitations on your product today </li><li>Sample scripts (if that fits in your skill set)</li></ol>",
    task: "Read your API documentation",
    phase: "Essentials",
    order: 8,
    key: "8f41904d-7b53-4329-b02c-6f1635ef5cb2",
    taskType: "Research",
  },
  {
    taskType: "Documentation",
    notes:
      "<h3>Background</h3><p><br></p><p>This is another reminder more than exercise. At this point, you likely have loads of notes on your Building Bedrock 🚀, but you need to turn that into reusable content. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What are the public documentation items you need to write? </li><li>What are the internal documentation items you need to write? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>New documentation </li></ol>",
    order: 9,
    task: "Fully document this process to make it repeatable for future hires",
    subscription: "starter",
    key: "ea147d0e-0df0-48d2-9a94-92098995c1c5",
    phase: "Essentials",
    status: "Todo",
    section: "Building Bedrock 🚀",
  },
  {
    status: "Todo",
    order: 10,
    subscription: "starter",
    key: "3f4e2a5f-6513-4403-89a8-94717deb6e06",
    task: "Use your Path to Product Expert Documentation to create an onboarding plan for new hires",
    notes:
      "<h3>Background</h3><p><br></p><p>Now that you've researched and documented everything under the sun, it's time to turn that into an internal onboarding plan. This should encompass all things related to your product that any new hire should know.</p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What internal documentation is important to review? </li><li>What external documentation is important to review? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Onboarding plan</li></ol>",
    section: "Building Bedrock 🚀",
    phase: "Essentials",
    taskType: "Action",
  },
  {
    key: "8d86ee7e-d1eb-4e6c-92ee-9f5ef2927a26",
    taskType: "Research",
    order: 11,
    notes:
      "<h3>Background</h3><p><br></p><p>At this point, you have likely identified the most common use cases based on your research in Building Bedrock 🚀. Now, it's time to fully flush them out internally to align on what you want to focus your efforts on. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What are the top use cases? </li><li>What are the steps to success in those use cases? </li><li>What are the key outcomes customers get from these use cases? </li><li>How can these be tied to ROI? </li><li>How can you ease the process of accomplishing these use cases? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Complete understanding of your top use cases and how to accomplish them</li><li>Documentation on best practices for accomplishing the use cases </li><li>Ideas for your sales team to hone in on these during the sales cycle</li><li>Ideas for your product team to ease the customer journey to these use cases</li></ol>",
    subscription: "starter",
    section: "Standard Success Outcomes 🙌",
    phase: "Essentials",
    status: "Todo",
    task: "Discuss common use cases with internal team",
  },
  {
    taskType: "Research",
    subscription: "starter",
    status: "Todo",
    order: 12,
    phase: "Essentials",
    notes:
      "<h3>Background</h3><p><br></p><p>Building off the use cases with the team. Identify customers that have found success with these use cases. You will want to think through this to prepare to validate those use cases with the customer as you build out your playbooks for success. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>Who are my most engaged customers? </li><li>Who is likely getting the highest ROI from our product? </li><li>Who is likely willing to chat more about these use cases? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Short list of customers to review and validate your success outcomes with </li></ol>",
    key: "8e7cc29e-3312-469f-ba82-d0e0abdfe5d2",
    task: "Discuss current customer successes with the team",
    section: "Standard Success Outcomes 🙌",
  },
  {
    section: "Standard Success Outcomes 🙌",
    notes:
      "<h3>Background</h3><p><br></p><p>As you hone in on use cases, you want to see if there are any themes to them. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>Persona based? </li><li>Product tier based? </li><li>Industry based? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Themes for your success outcomes </li></ol>",
    taskType: "Research",
    task: "Identify themes for those use cases and successes",
    phase: "Essentials",
    subscription: "starter",
    status: "Todo",
    key: "a8d98763-6352-432a-9f09-fba7bcecc582",
    order: 13,
  },
  {
    phase: "Essentials",
    section: "Standard Success Outcomes 🙌",
    status: "Todo",
    task: "Identify key results of those use cases and successes",
    subscription: "starter",
    order: 14,
    taskType: "Research",
    notes:
      "<h3>Background</h3><p><br></p><p>As you think through use cases for you product, you need to identify key results that allow you to track success on the use case. This will be useful for reviewing with customers and for health scoring in the future. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>Is there a key product milestone for each use case? </li><li>Is there a way to track a milestone back to some ROI metric? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Ideas for milestones to track in the product and to discuss with customers</li></ol>",
    key: "11e22ade-4f34-481e-bf51-f8225de34254",
  },
  {
    phase: "Essentials",
    section: "Standard Success Outcomes 🙌",
    taskType: "Documentation",
    subscription: "starter",
    status: "Todo",
    key: "cfc5648b-f039-4d84-a94d-23b944ab3493",
    notes:
      "<h3>Background</h3><p><br></p><p>As you work towards validating your success outcomes, you need to put it into a deliverable format to use as a conversation starter. You should also loosely document the steps to the outcome with the key results. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What are the themes? </li><li>What are the success outcomes? </li><li>What are the steps to accomplish each? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Deliverable slide to talk internally and with customers</li><li>Steps to success for each success outcome</li></ol>",
    task: "Create documentation and a slide that easily illustrates the success outcomes",
    order: 15,
  },
  {
    phase: "Essentials",
    section: "Standard Success Outcomes 🙌",
    taskType: "Action",
    status: "Todo",
    order: 16,
    key: "ad7f1d47-b0cb-4cdc-a687-93f6b225e3c0",
    notes:
      "<h3>Background</h3><p><br></p><p>Use your list of customers identified earlier to have an open discuss about success outcomes. Make sure to stay open to ideas vs selling them on what you came up with. </p><p><br></p><h3>Discovery Questions</h3><p><br></p><ol><li>What is the value you are getting from the product? </li><li>How does that translate to ROI for you? </li><li>How would you say these success outcomes align with your usage? </li><li>What are we missing? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Validated success outcomes</li><li>Potentially new success outcome ideas</li></ol>",
    task: "Get feedback on what was missed from your top 5-10 customers based on engagement",
    subscription: "starter",
  },
  {
    task: "Enable your internal team to hone in on these during the sales process and customer implementation",
    status: "Todo",
    order: 17,
    phase: "Essentials",
    key: "1d6cbd47-3f6d-4e24-a907-70a5877b6fe3",
    notes:
      "<h3>Background</h3><p><br></p><p>At this point, you have documented almost everything you need to hone in on these success outcomes. Now it's time to make them actionable. </p><h3><br></h3><h3>Discovery Questions</h3><p><br></p><ol><li>What does the sales team need to use these during pre sales? </li><li>What do customers need to make these outcomes easier to achieve? </li></ol><p><br></p><h3>Key Outcomes</h3><ol><li>Internal collateral for the sales process</li><li>Customer facing content that guides customers to each key success outcome </li></ol>",
    section: "Standard Success Outcomes 🙌",
    taskType: "Action",
    subscription: "starter",
  },
];

export const phasesExplainers = {
  Essentials:
    "Essentials are essential to any Customer Success program. These Sections and Tasks will be the building blocks for the rest of the program.",
  Efficiency:
    "Identifying Efficiencies will help set you up to scale out your program. Anything in the Efficiency Phase will help you create repeatable actions for your team and customers.",
  Enterprise:
    "Enterprise is what it is. As your team and customer base both grow, your program needs to be built to Scale.",
};

export const sectionExplainers = {
  "Building Bedrock 🚀":
    "First and foremost, you need to fully understand your product to deliver a top notch customer experience. This building block guides you through that process.",
  "Standard Success Outcomes 🙌":
    "Success Outcomes = Customer ROI. This building block shows you how to capture repeatable outcomes for your customers.",
  "Onboarding Outline 📈":
    "Once you know the outcomes you want to repeat, it's time to help customers do it. This building block guides you down the path of scaling that process out.",
  "Automating Activities 🤖":
    "You cannot scale without automation. This building block provides a repeatable process for automating any activity.",
  "Customer Health Hero 🦸":
    "Customer Health allows you to stay proactive rather than reactive. This building block provides a playbook for implementing Customer Health Scoring.",
  "Geared for Growth 🔥":
    "In order to scale, you need a plan of attack. This building block walks you through that process to provide confidence in your team growth plan.",
};

export const sectionTiers = {
  "Building Bedrock 🚀": "starter",
  "Standard Success Outcomes 🙌": "starter",
  "Onboarding Outline 📈": "standard",
  "Automating Activities 🤖": "standard",
  "Customer Health Hero 🦸": "standard",
  "Geared for Growth 🔥": "standard",
};

export const sectionPhases = {
  "Building Bedrock 🚀": "Essentials",
  "Standard Success Outcomes 🙌": "Essentials",
  "Onboarding Outline 📈": "Efficiency",
  "Automating Activities 🤖": "Efficiency",
  "Customer Health Hero 🦸": "Enterprise",
  "Geared for Growth 🔥": "Enterprise",
};

export const sectionOutcomes = {
  "Building Bedrock 🚀": [
    {
      children: "Reduce CSM Onboarding Time by 50%+",
      color: "#ab7ae0",
    },
    {
      children: "Consolidated Onboarding Plan for CSMs",
      color: "#ab7ae0",
    },
    { children: "Improvements for External Documentation", color: "#ab7ae0" },
  ],
  "Standard Success Outcomes 🙌": [
    {
      children: "Reduce Time to Value by 25%+",
      color: "#ab7ae0",
    },
    {
      children: "Alignment with Marketing and Sales on Use Cases",
      color: "#ab7ae0",
    },
    {
      children: "Alignment with Product on Priorities",
      color: "#ab7ae0",
    },
  ],
  "Onboarding Outline 📈": [
    {
      children: "Reduce Onboarding Time by 50%+",
      color: "#ab7ae0",
    },
    {
      children: "Standardized Onboarding Outline",
      color: "#ab7ae0",
    },
    { children: "Reduced Support Burden", color: "#ab7ae0" },
  ],
  "Automating Activities 🤖": [
    {
      children: "Save 10+ Hours per Week",
      color: "#ab7ae0",
    },
    {
      children: "Reduce CSM Burnout",
      color: "#ab7ae0",
    },
    { children: "Consistency Across the Customer Base", color: "#ab7ae0" },
  ],
  "Customer Health Hero 🦸": [
    {
      children: "Flip your program from Reactive to Proactive",
      color: "#ab7ae0",
    },
    {
      children: "Red Account Playbooks",
      color: "#ab7ae0",
    },
    { children: "Health Based Renewal Forecasting", color: "#ab7ae0" },
  ],
  "Geared for Growth 🔥": [
    {
      children:
        "Alignment on Personas Needed for Your Customer Success Program",
      color: "#ab7ae0",
    },
    {
      children:
        "Job Descriptions and Hiring Processes for Your Customer Success Team",
      color: "#ab7ae0",
    },
    {
      children: "Confidence in How Your Customer Success Program Will Scale",
      color: "#ab7ae0",
    },
  ],
};

export type SectionTypes =
  | "Building Bedrock 🚀"
  | "Standard Success Outcomes 🙌"
  | "Onboarding Outline 📈"
  | "Automating Activities 🤖"
  | "Customer Health Hero 🦸"
  | "Geared for Growth 🔥";

export const starterSections: SectionTypes[] = [
  "Building Bedrock 🚀",
  "Standard Success Outcomes 🙌",
];

export const standardSections: SectionTypes[] = [
  "Building Bedrock 🚀",
  "Standard Success Outcomes 🙌",
  "Onboarding Outline 📈",
  "Automating Activities 🤖",
  "Customer Health Hero 🦸",
  "Geared for Growth 🔥",
];

export const starterPhases = ["Essentials"];

export const standardPhases = ["Essentials", "Efficiency", "Enterprise"];

export const typeExplainers = {
  Research:
    "Research type tasks mean getting into the weeds. This can be in your product, with your team, and with your customers. Think like a scientist during these tasks.",
  Documentation:
    "Documentation is not always the funnest part of building a Customer Success program, but it's highly critical to success. Everything you learn along the way should be documented in order to make things repeatable.",
  Action:
    "Action type tasks oftentimes involve collaborating with Engineering, Sales Operations, or Marketing. While you will be able to do a lot of things, building a Customer Success progam requires collaboration to succeed.",
};

export const typeOrders = {
  Research: 1,
  Documentation: 2,
  Action: 3,
};
