import axios from "axios";

export const test = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = window.location.href.includes("localhost")
      ? "http://127.0.0.1:5001/mellasa-1f9c6/us-central1/initialTest"
      : "https://mellasa.com/api/inititalTest";
    axios
      .post(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          test: "test",
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch(() => {
        reject("error");
      });
  });
};

export const createInvoice = ({
  email,
  accountUID,
  subscription,
}: {
  email: string;
  accountUID: string;
  subscription: "standard" | "supported" | "starter";
}): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = window.location.href.includes("localhost")
      ? "http://127.0.0.1:5001/mellasa-1f9c6/us-central1/createInvoice"
      : "https://mellasa.com/api/createInvoice";
    axios
      .post(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          email: email,
          accountUID: accountUID,
          subscription: subscription,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkInvoiceStatus = ({
  invoiceUID,
}: {
  invoiceUID: string;
}): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = window.location.href.includes("localhost")
      ? "http://127.0.0.1:5001/mellasa-1f9c6/us-central1/checkInvoiceStatus"
      : "https://mellasa.com/api/checkInvoiceStatus";
    axios
      .post(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          invoiceUID: invoiceUID,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const cancelInvoice = ({
  invoiceUID,
}: {
  invoiceUID: string;
}): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = window.location.href.includes("localhost")
      ? "http://127.0.0.1:5001/mellasa-1f9c6/us-central1/cancelInvoice"
      : "https://mellasa.com/api/cancelInvoice";
    axios
      .post(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          invoiceUID: invoiceUID,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch(() => {
        reject("error");
      });
  });
};
