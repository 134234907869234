import React from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Statistic, Button, Image } from "antd";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Hero } from "../components/Hero";
import { How } from "../components/How";
import { PageGap } from "../components/PageGap";
import logo from "../assets/mellasa-logo.svg";

const formatter = (value: any) => <CountUp end={value} separator="," />;

export const HomeLogin = () => {
  return (
    <React.Fragment>
      <Row className="full-row page-header">
        <Image src={logo} height="100px" />
      </Row>
      <Row className="full-row">
        <h1 className="hero-title">We Help Founders Build Customer Success</h1>
      </Row>
      <Row gutter={16} className="full-row page-gap">
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Statistic
            title="Net Revenute Retention"
            value={125}
            precision={2}
            valueStyle={{ color: "#6abe39" }}
            prefix={<ArrowUpOutlined />}
            formatter={formatter}
            suffix="%"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Statistic
            title="Churn"
            value={3}
            precision={2}
            valueStyle={{ color: "#a61d24" }}
            prefix={<ArrowDownOutlined />}
            formatter={formatter}
            suffix="%"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Statistic
            title="Renewal Rates"
            value={90}
            precision={2}
            valueStyle={{ color: "#6abe39" }}
            prefix={<ArrowUpOutlined />}
            formatter={formatter}
            suffix="%"
          />
        </Col>
      </Row>
      <Row className="full-row page-gap">
        <Button size="large" className="button-set">
          <Link to="/preview">See Mellasa in Action</Link>
        </Button>
        <Button size="large" className="button-set">
          <Link to="/signup">Create Account</Link>
        </Button>
      </Row>
      <PageGap />
      <Divider className="full-row big-page-gap" />
      <Hero
        title="About Us 👋"
        explainer="Mellasa was created to provide a practical approach to Customer
            Success. Cut through the noise and make progress."
      />
      <PageGap />
      <Divider className="full-row big-page-gap" />
      <How />
      <PageGap />
      <PageGap />
    </React.Fragment>
  );
};
