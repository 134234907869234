import React, { useEffect, useState } from "react";
import { Button, Row, Table, Space, Form, Select, Input, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  inviteUserToAccount,
  getAccountbyUID,
  AccountType,
} from "../services/Database";
import { Hero } from "../components/Hero";
import { useAuthListener } from "../services/Authentication";
import { Subscription } from "./Subscription";

interface UserTable {
  key: string;
  email: string;
  role: string;
}

export const Account = () => {
  const { accountUID, subscription } = useAuthListener();
  const [manageSubscription, setManageSubscription] = useState<boolean>(true);
  const [manageUsers, setManageUsers] = useState<boolean>(false);
  const [inviteUser, setInviteUser] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountType>();
  const [userList, setUserList] = useState<UserTable[]>();
  const [loading, setLoading] = useState(true);
  const [msg, contextHolder] = message.useMessage();

  const userTableColumns: ColumnsType<UserTable> = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => (
        <Space>
          <p>{record.email}</p>
        </Space>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_, record) => (
        <Space>
          <Form
            initialValues={{
              role: record.role,
            }}
            className="table-cell-min"
          >
            <Form.Item name="role">
              <Select
                onChange={(value: "viewer" | "user" | "admin") =>
                  console.log("TODO: make role updates work")
                }
              >
                <Select.Option value="viewer">Viewer</Select.Option>
                <Select.Option value="user">User</Select.Option>
                <Select.Option value="admin">Admin</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (accountUID) {
      setLoading(true);
      getAccountbyUID(accountUID).then((account) => {
        let tempUserList: UserTable[] = [];
        account.userList.forEach((u: string) => {
          tempUserList.push({ key: u, email: u, role: account.userRoles[u] });
        });
        setUserList(tempUserList);
        setAccount(account);
        setLoading(false);
      });
    }
  }, [accountUID]);

  const onFinish = (values: any) => {
    if (values.email && values.role && accountUID) {
      inviteUserToAccount({
        accountUID: accountUID,
        userEmail: values.email,
        userRole: values.role,
      })
        .then(() => {
          setInviteUser(false);
          msg.destroy();
          msg.success(`Invited ${values.email} with role ${values.role}`, 3);
        })
        .catch((error) => {
          console.log(error);
          msg.destroy();
          msg.warning(
            "The Supported subscription is not yet available. Email hey@mellasa.com to join the waitlist.",
            3
          );
        });
    } else {
      console.log("Missing required data");
    }
  };

  return (
    <React.Fragment>
      {!loading && account && (
        <React.Fragment>
          {contextHolder}
          <Hero title={account?.name} buttons={[]} />
          {(!manageSubscription || !manageUsers) && (
            <Row className="full-row">
              {!manageSubscription && (
                <Button
                  onClick={() => {
                    setManageUsers(false);
                    setInviteUser(false);
                    setManageSubscription(true);
                  }}
                  className="small-space"
                >
                  Manage Subscription
                </Button>
              )}
              {!manageUsers && (
                <Button
                  onClick={() => {
                    if (subscription === "starter") {
                      msg.destroy();
                      msg.warning(
                        "Team funcationality is not included with the Starter subscription. Please upgrade to manage users.",
                        3
                      );
                    } else {
                      setInviteUser(false);
                      setManageSubscription(false);
                      setManageUsers(true);
                    }
                  }}
                  className="small-space"
                >
                  Manage Users
                </Button>
              )}
              {!inviteUser && (
                <Button
                  onClick={() => {
                    if (subscription === "starter") {
                      msg.destroy();
                      msg.warning(
                        "Team funcationality is not included with the Starter subscription. Please upgrade to invite teammates",
                        3
                      );
                    } else {
                      setManageUsers(false);
                      setManageSubscription(false);
                      setInviteUser(true);
                    }
                  }}
                  className="small-space"
                >
                  Invite User
                </Button>
              )}
            </Row>
          )}
        </React.Fragment>
      )}
      {!loading && inviteUser && account && !!!(subscription === "starter") && (
        <Row className="full-row">
          <Space direction="vertical">
            <Hero title="Invite User" />
            <Form
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              initialValues={{
                role: "user",
              }}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please add email",
                    type: "email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please add role" }]}
              >
                <Select>
                  <Select.Option value="viewer">Viewer</Select.Option>
                  <Select.Option value="user">User</Select.Option>
                  <Select.Option value="admin">Admin</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Invite
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Row>
      )}
      {!loading &&
        manageUsers &&
        account &&
        !!!(subscription === "starter") && (
          <Row className="full-row">
            <Space direction="vertical">
              <Hero title="Manage Users" />
              <Table columns={userTableColumns} dataSource={userList} />
            </Space>
          </Row>
        )}
      {!loading && manageSubscription && <Subscription />}
    </React.Fragment>
  );
};
