import React, { useEffect, useState } from "react";
import { Row, List, Space } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useAuthListener } from "../services/Authentication";
import { checkInvoiceStatus, createInvoice } from "../services/Backend";
import {
  updateSubscription,
  updateInvoicePublicUrl,
  useTaskHistory,
} from "../services/Database";
import { useNavigate } from "react-router-dom";
import { Hero } from "../components/Hero";

export const InitializingAccount = () => {
  const {
    userUID,
    accountUID,
    paymentPending,
    invoiceUID,
    invoicePublicUrl,
    subscription,
    userEmail,
    setCheckingAuthentication,
  } = useAuthListener();
  const { tasks } = useTaskHistory({
    userUID: userUID ? userUID : undefined,
    accountUID: accountUID ? accountUID : undefined,
  });
  const [done, setDone] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [tasksCreated, setTasksCreated] = useState(false);
  const [invoiceCreated, setInvoiceCreated] = useState(false);
  const [invoiceStarted, setInvoiceStarted] = useState(false);
  const [invoiceSent, setInvoiceSent] = useState(false);
  const [error, setError] = useState<"creating" | "checking">();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      if (userEmail) {
        setUserCreated(true);
      } else {
        setDone(false);
      }
      if (accountUID && subscription) {
        setAccountCreated(true);
      } else {
        setDone(false);
      }
      if (tasks) {
        setTasksCreated(true);
      } else {
        setDone(false);
      }
      if (invoiceUID || subscription === "starter") {
        setInvoiceCreated(true);
      } else if (accountUID && subscription && userEmail && !invoiceStarted) {
        setInvoiceStarted(true);
        createInvoice({
          email: userEmail,
          accountUID: accountUID,
          subscription: subscription,
        })
          .then((resp) => {
            updateSubscription({
              accountUID: accountUID,
              subscription: subscription,
              squareBillingContact: userEmail,
              squareCustomerId: resp.data.customerId,
              squareOrderId: resp.data.orderId,
              squareInvoiceId: resp.data.invoiceId,
              squarePublicUrl:
                resp.data?.publicUrl === undefined ? resp.data.publicUrl : "",
            }).then(() => {
              setCheckingAuthentication(true);
            });
          })
          .catch((error) => {
            console.log(error);
            setError("creating");
            setInvoiceStarted(false);
          });
      } else {
        setDone(false);
      }
      if (invoicePublicUrl || subscription === "starter") {
        setInvoiceSent(true);
      } else if (paymentPending && invoiceUID) {
        checkInvoiceStatus({ invoiceUID: invoiceUID })
          .then((resp) => {
            if (resp.data.publicUrl !== undefined) {
              updateInvoicePublicUrl({
                accountUID: accountUID,
                squarePublicUrl: resp.data.publicUrl,
              }).then(() => {
                setCheckingAuthentication(true);
              });
            }
          })
          .catch((error) => {
            console.log(error);
            setError("checking");
          });
      } else {
        setDone(false);
      }
      if (
        userCreated &&
        accountCreated &&
        tasksCreated &&
        invoiceCreated &&
        invoiceSent
      ) {
        setDone(true);
        navigate("/tasks");
      } else {
        setCheckingAuthentication(true);
        setDone(false);
      }
    }, 3000);
    return () => clearInterval(timer);
  }, [
    userEmail,
    accountUID,
    tasksCreated,
    tasks,
    subscription,
    paymentPending,
    invoicePublicUrl,
    invoiceUID,
    done,
    accountCreated,
    userCreated,
    invoiceCreated,
    invoiceSent,
    setCheckingAuthentication,
    navigate,
    invoiceStarted,
  ]);

  return (
    <React.Fragment>
      {!!error && (
        <Hero
          title={`There was an error ${error} your invoice.`}
          explainer="Please refresh the page to try again. If this issue persists, please contact support@mellasa.com to get help."
        />
      )}
      {!error && (
        <React.Fragment>
          <Hero title="Updating Account" />
          <Row className="full-row">
            <List size="small" bordered>
              <List.Item>
                <Space>
                  User Created{" "}
                  {userCreated ? (
                    <CheckCircleOutlined />
                  ) : (
                    <LoadingOutlined spin />
                  )}
                </Space>
              </List.Item>
              <List.Item>
                <Space>
                  Account Created{" "}
                  {accountCreated ? (
                    <CheckCircleOutlined />
                  ) : (
                    <LoadingOutlined spin />
                  )}
                </Space>
              </List.Item>
              <List.Item>
                <Space>
                  Tasks Created{" "}
                  {tasksCreated ? (
                    <CheckCircleOutlined />
                  ) : (
                    <LoadingOutlined spin />
                  )}
                </Space>
              </List.Item>
              {(subscription === "standard" ||
                subscription === "supported") && (
                <List.Item>
                  <Space>Invoice Needed {<CheckCircleOutlined />}</Space>
                </List.Item>
              )}
              {(subscription === "standard" ||
                subscription === "supported") && (
                <List.Item>
                  <Space>
                    Invoice Created{" "}
                    {invoiceCreated ? (
                      <CheckCircleOutlined />
                    ) : (
                      <LoadingOutlined spin />
                    )}
                  </Space>
                </List.Item>
              )}
              {(subscription === "standard" ||
                subscription === "supported") && (
                <List.Item>
                  <Space>
                    Invoice Sent{" "}
                    {invoiceSent ? (
                      <CheckCircleOutlined />
                    ) : (
                      <LoadingOutlined spin />
                    )}
                  </Space>
                </List.Item>
              )}
            </List>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
