import React, { useEffect, useState } from "react";
import { Row, List, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuthListener } from "../services/Authentication";
import { checkInvoiceStatus, createInvoice } from "../services/Backend";
import {
  updateSubscription,
  updateInvoicePublicUrl,
  updateInvoiceStatus,
} from "../services/Database";
import { Link } from "react-router-dom";
import { Hero } from "./Hero";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const InvoiceStatus = () => {
  const {
    accountUID,
    paymentPending,
    invoiceUID,
    billingContact,
    invoicePublicUrl,
    subscription,
    userEmail,
  } = useAuthListener();
  const [invoiceStatus, setInvoiceStatus] = useState<string>();
  const [creatingInvoice, setCreatingInvoice] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (paymentPending === true && invoiceUID !== undefined) {
      checkInvoiceStatus({ invoiceUID: invoiceUID })
        .then((resp) => {
          console.log(resp.data.status);
          if (resp.data.status !== undefined) {
            setInvoiceStatus(resp.data.status);
            if (resp.data.status === "PAID") {
              updateInvoiceStatus({
                accountUID: accountUID,
                squareInvoiceStatus: resp.data.status,
              }).then(() => {
                setInvoiceStatus("PAID");
              });
            }
          }
          if (resp.data.publicUrl !== undefined) {
            updateInvoicePublicUrl({
              accountUID: accountUID,
              squarePublicUrl: resp.data.publicUrl,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        });
    } else if (paymentPending === true && invoiceUID === undefined) {
      // create invoice
      if (accountUID && subscription && userEmail) {
        setCreatingInvoice(true);
        createInvoice({
          email: userEmail,
          accountUID: accountUID,
          subscription: subscription,
        })
          .then((resp) => {
            updateSubscription({
              accountUID: accountUID,
              subscription: subscription,
              squareBillingContact: userEmail,
              squareCustomerId: resp.data.customerId,
              squareOrderId: resp.data.orderId,
              squareInvoiceId: resp.data.invoiceId,
              squarePublicUrl:
                resp.data?.publicUrl === undefined ? resp.data.publicUrl : "",
            });
            setCreatingInvoice(false);
          })
          .catch((error) => {
            console.log(error);
            console.log("setting error true");
            setError(true);
            setCreatingInvoice(false);
          });
      }
    }
  }, [paymentPending, invoiceUID, accountUID, subscription, userEmail]);

  useEffect(() => {
    if (invoiceUID && invoicePublicUrl === undefined) {
      window.location.reload();
    }
  }, [invoiceUID, invoicePublicUrl]);

  return (
    <Row className="full-row">
      {error && (
        <Hero
          title="There was an error checking your invoice."
          explainer="Please refresh the page to try again. If this issue persists, please contact support@mellasa.com to get help."
        />
      )}
      {!error && (
        <Space direction="vertical">
          <Hero title="Your Mellasa Payment Is Not Complete" />
          {!creatingInvoice && (
            <React.Fragment>
              <Row className="full-row">
                <List size="small" bordered>
                  <List.Item>Account ID: {accountUID}</List.Item>
                  <List.Item>Invoice ID: {invoiceUID}</List.Item>
                  <List.Item>
                    Current Billing Contact: {billingContact}
                  </List.Item>
                  <List.Item>Invoice Status: {invoiceStatus}</List.Item>
                  {invoicePublicUrl !== "" &&
                    invoicePublicUrl !== undefined && (
                      <List.Item>
                        Invoice URL:{" "}
                        <Link
                          to={
                            invoicePublicUrl.includes("https://")
                              ? invoicePublicUrl
                              : "https://" + invoicePublicUrl
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="link"
                        >
                          View Invoice
                        </Link>
                      </List.Item>
                    )}
                </List>
              </Row>
            </React.Fragment>
          )}
          {!creatingInvoice && subscription && (
            <React.Fragment>
              <p className="page-gap">
                You can still use the Essential Building Blocks until your{" "}
                {subscription.toUpperCase()[0] + subscription.slice(1)}{" "}
                subscription is paid for. Refresh the page to update the invoice
                status.
              </p>
              <p style={{ marginBottom: "10vh" }}>
                If you think this is an error, please reach out to
                support@mellasa.com.
              </p>
            </React.Fragment>
          )}
          {creatingInvoice && (
            <Spin tip="Creating Invoice" size="large" indicator={antIcon}>
              <Row className="full-row" style={{ minHeight: "10vh" }}></Row>
            </Spin>
          )}
        </Space>
      )}
    </Row>
  );
};
