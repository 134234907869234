import React from "react";
import { Row, Col, Card, List, Divider, Button, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useState } from "react";
import {
  starterTier,
  standardTier,
  supportedTier,
  maxSubscription,
} from "../static/Tiers";
import { Hero } from "../components/Hero";
import { InvoiceStatus } from "../components/InvoiceStatus";
import { useAuthListener, SubscriptionType } from "../services/Authentication";
import { updateAccountSubscription, subscriptions } from "../services/Database";
import { useNavigate } from "react-router-dom";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const Subscription = () => {
  const { accountUID, paymentPending, invoiceUID, subscription } =
    useAuthListener();
  const [newTier, setNewTier] = useState<SubscriptionType>("standard");
  const [creatingInvoice, setCreatingInvoice] = useState(false);
  const [msg, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {contextHolder}
      <React.Fragment>
        <Hero
          title="Manage Mellasa Subscription"
          explainer="Costs less than churning a single customer"
        />
        <Divider />
        {paymentPending && <InvoiceStatus />}
        {!paymentPending && subscription === "standard" && (
          <React.Fragment>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 24 }}
              className="full-row-tall"
              align="stretch"
            >
              <Col xs={20} sm={20} md={20} lg={4} xl={4} className="flex">
                <Card title="Standard" className="active-bg">
                  <List
                    dataSource={maxSubscription}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                    className="lefty"
                  />
                </Card>
              </Col>
              <Col xs={20} sm={20} md={20} lg={4} xl={4} className="flex">
                <Card
                  title="Supported"
                  onClick={() => {
                    msg.destroy();
                    msg.warning(
                      "The Supported subscription is not yet available. Email hey@mellasa.com to join the waitlist.",
                      3
                    );
                  }}
                  hoverable={false}
                >
                  <List
                    dataSource={supportedTier}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                    className="lefty"
                  />
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {!paymentPending && !invoiceUID && (
          <React.Fragment>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 24 }}
              className="full-row-tall"
              align="stretch"
            >
              {subscriptions.map((t: SubscriptionType, i) => {
                if (t) {
                  return (
                    <Col
                      key={`sign-up-subscriptions-${i}`}
                      xs={20}
                      sm={20}
                      md={20}
                      lg={4}
                      xl={4}
                      className="flex"
                    >
                      <Card
                        title={t.charAt(0).toUpperCase() + t.slice(1)}
                        onClick={() => {
                          if (t === "standard") {
                            setNewTier(t);
                          } else {
                            msg.destroy();
                            msg.warning(
                              "The Supported subscription is not yet available. Email hey@mellasa.com to join the waitlist.",
                              3
                            );
                          }
                        }}
                        hoverable={
                          t === "starter" || t === "standard" ? true : false
                        }
                        className={t === newTier ? "active-bg" : undefined}
                      >
                        <List
                          dataSource={
                            t === "starter"
                              ? starterTier
                              : t === "standard"
                              ? standardTier
                              : supportedTier
                          }
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                          className="lefty"
                        />
                      </Card>
                    </Col>
                  );
                } else return undefined;
              })}
            </Row>

            <Row className="full-row page-header">
              {!creatingInvoice && (
                <Button
                  onClick={() => {
                    setCreatingInvoice(true);
                    if (accountUID && newTier) {
                      updateAccountSubscription({
                        accountUID: accountUID as string,
                        subscription: newTier,
                      }).then(() => {
                        setCreatingInvoice(false);
                        navigate("/initializing");
                      });
                    } else {
                      msg.destroy();
                      msg.error(
                        "Update Tier Failed. Please try again. If this continues, please reach out to support@mellasa.com",
                        3
                      );
                    }
                  }}
                  className="button-set"
                >
                  Upgrade Subscription
                </Button>
              )}
              {creatingInvoice && (
                <Row className="full-row" style={{ minHeight: "20vh" }}>
                  <Spin tip="Creating Invoice" size="large" indicator={antIcon}>
                    <Row className="full-row"></Row>
                  </Spin>
                </Row>
              )}
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};
