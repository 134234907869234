import React, { useEffect, useState } from "react";
import {
  getUpgradeTasks,
  batchCreateTasks,
  useTaskHistory,
  inviteResponse,
} from "../services/Database";
import { standardSections } from "../static/DefaultBuildPlan";
import { Button, Divider, Row, message } from "antd";
import { useAuthListener } from "../services/Authentication";
import { BuildingBlocksTable } from "../components/BuildingBlocksTable";
import { Hero } from "../components/Hero";
import { InvoiceStatus } from "../components/InvoiceStatus";
import "../css/Quill.css";
import { useNavigate } from "react-router-dom";

export const Tasks = () => {
  const {
    paymentPending,
    waitingForDB,
    user,
    userUID,
    accountUID,
    inviteUID,
    inviteName,
    subscription,
  } = useAuthListener();
  const { tasks, loading } = useTaskHistory({
    accountUID: accountUID ? accountUID : undefined,
    userUID: userUID ? userUID : undefined,
  });
  const [invitePending, setInvitePending] = useState(false);
  const [checkForInvite, setCheckForInvite] = useState(true);
  const navigate = useNavigate();
  const [msg, contextHolder] = message.useMessage();

  useEffect(() => {
    if (
      !waitingForDB &&
      checkForInvite &&
      user &&
      inviteUID &&
      accountUID &&
      inviteUID !== accountUID
    ) {
      setInvitePending(true);
    } else {
      setInvitePending(false);
    }
  }, [
    waitingForDB,
    subscription,
    tasks,
    invitePending,
    checkForInvite,
    user,
    inviteUID,
    accountUID,
    loading,
  ]);

  useEffect(() => {
    if (
      ["standard", "supported"].includes(subscription) &&
      !paymentPending &&
      tasks
    ) {
      let tempSections: string[] = [];
      standardSections.forEach((section) => {
        if (tasks.filter((t) => section.includes(t.section)).length === 0) {
          tempSections.push(section.slice(0, section.length - 3));
        }
      });
      if (tempSections.length > 0) {
        getUpgradeTasks({ sections: tempSections }).then((tasks) => {
          console.log(tasks);
          batchCreateTasks({
            tasks: tasks,
            userUID: userUID,
            accountUID: accountUID,
          });
        });
      }
    }
  }, [subscription, tasks, paymentPending, accountUID, userUID, loading]);

  return (
    <React.Fragment>
      {contextHolder}
      {!loading && waitingForDB && (
        <Hero
          title="Your Account Is Being Created"
          explainer="Please refresh this page in a few seconds to check if it's done."
        />
      )}
      {!loading && checkForInvite && invitePending && (
        <React.Fragment>
          <Hero
            title="You Have a Pending Account Invite"
            explainer={`Would you like to join the account named ${inviteName}?`}
          />
          <Row className="full-row">
            <Button
              type="primary"
              size="large"
              className="small-space"
              onClick={() => {
                inviteResponse({
                  accountUID: inviteUID,
                  accept: true,
                  userEmail: user.email,
                  userUID: userUID,
                }).then(() => {
                  navigate("/initializing");
                });
                setCheckForInvite(false);
                setInvitePending(false);
                msg.destroy();
                msg.success(
                  "Accepted invitation. Your task list will now be updated to match the account."
                );
              }}
            >
              Accept
            </Button>
            <Button
              type="dashed"
              size="large"
              className="small-space"
              onClick={() => {
                inviteResponse({
                  accountUID: inviteUID,
                  accept: false,
                  userEmail: user.email,
                  userUID: userUID,
                });
                setCheckForInvite(false);
                setInvitePending(false);
                msg.destroy();
                msg.warning(
                  "Invitation declined. If this was a mistake, ask to be invited again."
                );
              }}
            >
              Decline
            </Button>
          </Row>
        </React.Fragment>
      )}
      {!loading && tasks && !invitePending && (
        <React.Fragment>
          {paymentPending === true && (
            <React.Fragment>
              <InvoiceStatus />
              <Divider />
            </React.Fragment>
          )}
          <Hero title="Customer Success Building Blocks" />
          <BuildingBlocksTable
            buildPlan={tasks}
            userUID={userUID}
            subscription={subscription}
            loading={loading}
            page="product"
            paymentPending={paymentPending}
          />{" "}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
