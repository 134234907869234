import React, { ReactNode } from "react";
import {
  ProfileOutlined,
  HomeOutlined,
  LoginOutlined,
  PlusCircleOutlined,
  LogoutOutlined,
  TeamOutlined,
  ToolOutlined,
  SearchOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Layout as AntLayout, Menu, MenuProps, theme } from "antd";
import { Link } from "react-router-dom";
import { adios } from "../services/Authentication";

const { Header, Content, Footer, Sider } = AntLayout;

interface LayoutProps {
  authenticated: boolean;
  children: ReactNode;
}

const authenticatedItems: MenuProps["items"] = [
  {
    key: "tasks",
    label: <Link to="/tasks">Tasks</Link>,
    icon: <ToolOutlined />,
  },
  {
    key: "reporting",
    label: <Link to="/reporting">Reporting</Link>,
    icon: <ProfileOutlined />,
  },
  {
    key: "subscription",
    label: <Link to="/subscription">Subscription</Link>,
    icon: <DollarOutlined />,
  },
  {
    key: "account",
    label: <Link to="/account">Account</Link>,
    icon: <TeamOutlined />,
  },
  {
    key: "signin",
    label: (
      <a href="/signin" onClick={adios}>
        Sign out
      </a>
    ),
    icon: <LogoutOutlined />,
  },
];

const unauthenticatedItems: MenuProps["items"] = [
  {
    key: "home",
    label: <Link to="/">Home</Link>,
    icon: <HomeOutlined />,
  },
  {
    key: "preview",
    label: <Link to="/preview">Preview</Link>,
    icon: <SearchOutlined />,
  },
  {
    key: "signin",
    label: <Link to="/signin">Sign in</Link>,
    icon: <LoginOutlined />,
  },
  {
    key: "signup",
    label: <Link to="/signup">Sign up</Link>,
    icon: <PlusCircleOutlined />,
  },
];

export const Layout = ({ authenticated, children }: LayoutProps) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const defaultSelection =
    window.location.pathname.slice(1) === ""
      ? "home"
      : window.location.pathname.slice(1);

  const items = authenticated ? authenticatedItems : unauthenticatedItems;
  return (
    <AntLayout className="default-font-color">
      <Sider breakpoint="lg" collapsedWidth="0" className="default-bg">
        <Menu
          theme="dark"
          mode="inline"
          items={items}
          defaultSelectedKeys={[defaultSelection]}
          className="default-bg"
        />
      </Sider>
      <AntLayout>
        <Header style={{ padding: 0, background: colorBgContainer }} />
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>Mellasa ©2023</Footer>
      </AntLayout>
    </AntLayout>
  );
};
