import React from "react";
import { Row, Button } from "antd";
import { SectionCards } from "./SectionCards";
import { Link } from "react-router-dom";
import { Hero } from "./Hero";
import "../css/How.css";

export interface HowProps {
  color: "primary" | "secondary";
}

export const How = () => {
  return (
    <React.Fragment>
      <Hero
        title="How it Works 🔍"
        explainer="Mellasa breaks down building Customer Success into
            six building blocks - each building block below has a set of tasks with templates."
      />
      <Row className="full-row big-page-gap lefty">
        <SectionCards />
      </Row>
      <Row className="full-row page-gap">
        <Button size="large" className="button-set">
          <Link to="/preview">See Sample Tasks</Link>
        </Button>
        <Button size="large" className="button-set">
          <Link to="/signup">Create Account</Link>
        </Button>
      </Row>
    </React.Fragment>
  );
};
