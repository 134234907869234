import { useEffect, useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  OAuthProvider,
  sendEmailVerification,
  User,
} from "firebase/auth";
import { app } from "./Firebase";
import {
  addUpdateUser,
  getSelf,
  checkForAccountInvite,
  getAccountbyUID,
} from "./Database";

const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");
const auth = getAuth(app);

export type SubscriptionType = "starter" | "standard" | "supported" | undefined;
export const subscriptions: SubscriptionType[] = [
  "starter",
  "standard",
  "supported",
];

type UserPasswordCredential = {
  email: string;
  password: string;
  subscription?: SubscriptionType;
};

export const createUserPassword = ({
  email,
  password,
  subscription,
}: UserPasswordCredential): Promise<boolean> => {
  return new Promise(function (resolve, reject) {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        if (userCredential && subscription) {
          addUpdateUser(userCredential, subscription)
            .then(() => {
              sendEmailVerification(userCredential.user).then(() => {
                resolve(true);
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        resolve(errorCode);
      });
  });
};

export const signInUserPassword = ({
  email,
  password,
}: UserPasswordCredential): Promise<boolean | any> => {
  return new Promise(function (resolve, reject) {
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        addUpdateUser(user)
          .then(() => resolve(true))
          .catch((error) => {
            console.log(error);
          });
        resolve(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        reject(errorCode);
      });
  });
};

export const googleLogin = (
  subscription?: SubscriptionType | undefined
): Promise<boolean | any> => {
  return new Promise(function (resolve, reject) {
    console.log("logging in with google");
    signInWithPopup(auth, googleProvider)
      .then((user) => {
        if (user) {
          addUpdateUser(user, subscription ? subscription : undefined)
            .then((resp) => {
              resolve(resp);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        resolve(false);
      });
  });
};

export const microsoftLogin = (
  subscription?: SubscriptionType
): Promise<boolean | any> => {
  return new Promise(function (resolve, reject) {
    signInWithPopup(auth, microsoftProvider)
      .then((user) => {
        if (user) {
          addUpdateUser(user, subscription ? subscription : undefined)
            .then(() => resolve(true))
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        resolve(false);
      });
  });
};

export const adios = () => {
  signOut(auth);
};

export const useAuthListener = (): any => {
  const [authenticated, setAuthenticated] = useState<boolean>();
  const [verified, setVerified] = useState<boolean>();
  const [checkingAuthentication, setCheckingAuthentication] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userUID, setUserUID] = useState<string | undefined>(undefined);
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [accountUID, setAccountUID] = useState<string | undefined>(undefined);
  const [subscription, setSubscription] = useState<SubscriptionType>(undefined);
  const [inviteUID, setInviteUID] = useState<string | undefined>(undefined);
  const [inviteName, setInviteName] = useState<string | undefined>(undefined);
  const [waitingForDB, setWaitingForDB] = useState<boolean>(false);
  const [paymentPending, setPaymentPending] = useState<boolean>();
  const [invoiceUID, setInvoiceUID] = useState<boolean>();
  const [billingContact, setBillingContact] = useState<string>();
  const [invoicePublicUrl, setInvoicePublicUrl] = useState<string>();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCheckingAuthentication(true);
      if (user) {
        setAuthenticated(true);
        setUser(user);
        setUserUID(user.uid);
        setUserEmail(user?.email ? user.email : undefined);

        if (
          user.providerData[0].providerId === "password" &&
          user.emailVerified === false
        ) {
          setVerified(false);
        } else {
          setVerified(true);
        }

        getSelf(user.uid)
          .then((self) => {
            setAccountUID(self.accountUID);
            getAccountbyUID(self.accountUID)
              .then((account) => {
                if (account) {
                  setSubscription(account.subscription);
                  setPaymentPending(account.paymentPending ? true : false);
                  setInvoiceUID(account.squareInvoiceId);
                  setBillingContact(account.squareBillingContact);
                  setInvoicePublicUrl(account.squarePublicUrl);
                }
              })
              .catch((error) => {
                console.log(error);
                setWaitingForDB(true);
              });
          })
          .catch((error) => {
            console.log(error);
            setSubscription(undefined);
            setAccountUID(undefined);
            setWaitingForDB(true);
          });
        if (user.email) {
          checkForAccountInvite(user.email)
            .then((account) => {
              if (account) {
                setInviteUID(account.accountUID);
                setInviteName(account.name);
              } else {
                setInviteUID(undefined);
                setInviteName(undefined);
              }
            })
            .catch((error) => {
              console.log(error);
              setInviteUID(undefined);
              setInviteName(undefined);
            });
        }
        setCheckingAuthentication(false);
      } else {
        setCheckingAuthentication(false);
      }
    });
  }, [checkingAuthentication]);

  return {
    paymentPending,
    invoiceUID,
    billingContact,
    invoicePublicUrl,
    authenticated,
    setCheckingAuthentication,
    checkingAuthentication,
    userUID,
    userEmail,
    accountUID,
    inviteUID,
    inviteName,
    verified,
    user,
    subscription,
    waitingForDB,
  };
};
