import React from "react";
import { Card, Row, Col, Tooltip } from "antd";
import { useTaskHistory } from "../services/Database";
import { Bar, BarChart, BarSeries } from "reaviz";
import chroma from "chroma-js";
import { useAuthListener } from "../services/Authentication";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  standardSections,
  starterSections,
  standardPhases,
  starterPhases,
} from "../static/DefaultBuildPlan";

export const Reporting = () => {
  const { userUID, accountUID, subscription, paymentPending } =
    useAuthListener();
  const {
    taskCompletedCount,
    taskCount,
    taskPhaseCounts,
    taskSectionCounts,
    buildingBlocksComplete,
    phasesComplete,
    loading,
  } = useTaskHistory({
    accountUID: accountUID ? accountUID : undefined,
    userUID: userUID ? userUID : undefined,
  });

  const buildingBlocks =
    subscription === "starter" || paymentPending
      ? starterSections
      : standardSections;
  const phases =
    subscription === "starter" || paymentPending
      ? starterPhases
      : standardPhases;

  return (
    <React.Fragment>
      <Row gutter={{ xs: 8, sm: 16, md: 24 }} className="full-row">
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Card
            title="Phases Completed"
            loading={loading}
            className="small-space"
            extra={
              <Tooltip title="Phases Where All Tasks Are Done">
                <ExclamationCircleOutlined />
              </Tooltip>
            }
          >
            {(subscription === "starter" || paymentPending) &&
            phasesComplete >= 2
              ? phasesComplete - 2
              : phasesComplete}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Card
            title="Building Blocks Completed"
            loading={loading}
            className="small-space"
            extra={
              <Tooltip title="Building Blocks Where All Tasks Are Done">
                <ExclamationCircleOutlined />
              </Tooltip>
            }
          >
            {(subscription === "starter" || paymentPending) &&
            buildingBlocksComplete >= 4
              ? buildingBlocksComplete - 4
              : buildingBlocksComplete}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Card
            title="Tasks Completed"
            loading={loading}
            className="small-space"
            extra={
              <Tooltip title="Total Tasks Completed">
                <ExclamationCircleOutlined />
              </Tooltip>
            }
          >
            {taskCompletedCount}
          </Card>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24 }} className="secondary-row">
        {phases.map((phase, i) => {
          return (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={subscription === "starter" || paymentPending ? 24 : 8}
              xl={subscription === "starter" || paymentPending ? 24 : 8}
              key={`reporting-${i}`}
            >
              <Card title={`${phase}`} bordered={false} loading={loading}>
                {taskCount && taskPhaseCounts && (
                  <BarChart
                    height={350}
                    data={taskPhaseCounts.filter((t) => t.phase === phase)}
                    series={
                      <BarSeries
                        colorScheme={chroma
                          .scale(["#ab7ae0", "#3e2069"])
                          .correctLightness()
                          .colors(8)}
                        padding={0.1}
                        bar={<Bar />}
                      />
                    }
                  />
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24 }} className="secondary-row">
        {buildingBlocks.map((section, i) => {
          return (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              key={`reporting-foundations-sections-${i}`}
            >
              <Card title={`${section}`} bordered={false} loading={loading}>
                {taskCount && taskSectionCounts && (
                  <BarChart
                    height={350}
                    data={taskSectionCounts.filter((t) =>
                      section.includes(t.section)
                    )}
                    series={
                      <BarSeries
                        colorScheme={chroma
                          .scale(["#ab7ae0", "#3e2069"])
                          .correctLightness()
                          .colors(8)}
                        padding={0.1}
                        bar={<Bar />}
                      />
                    }
                  />
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24 }} className="secondary-row">
        {["Onboarding Outline", "Customer Health Hero"].map((section, i) => {
          return (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              key={`reporting-process-sections-${i}`}
            >
              <Card
                title={`${section} Tasks`}
                bordered={false}
                loading={loading}
              >
                {taskCount && taskSectionCounts && (
                  <BarChart
                    height={350}
                    data={taskSectionCounts.filter(
                      (t) => t.section === section
                    )}
                    series={
                      <BarSeries
                        colorScheme={chroma
                          .scale(["#ab7ae0", "#3e2069"])
                          .correctLightness()
                          .colors(8)}
                        padding={0.1}
                        bar={<Bar />}
                      />
                    }
                  />
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24 }} className="secondary-row">
        {["Automating Activities", "Geared for Growth"].map((section, i) => {
          return (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              key={`reporting-scaling-sections-${i}`}
            >
              <Card
                title={`${section} Tasks`}
                bordered={false}
                loading={loading}
              >
                {taskCount && taskSectionCounts && (
                  <BarChart
                    height={350}
                    data={taskSectionCounts.filter(
                      (t) => t.section === section
                    )}
                    series={
                      <BarSeries
                        colorScheme={chroma
                          .scale(["#ab7ae0", "#3e2069"])
                          .correctLightness()
                          .colors(8)}
                        padding={0.1}
                        bar={<Bar />}
                      />
                    }
                  />
                )}
              </Card>
            </Col>
          );
        })}
      </Row> */}
    </React.Fragment>
  );
};
