import React from "react";
import { Row, Col, Card, Tag, Tooltip, Space, Divider, Timeline } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  sectionExplainers,
  standardSections,
  sectionTiers,
  sectionPhases,
  sectionOutcomes,
  SectionTypes,
} from "../static/DefaultBuildPlan";

export const SectionCards = () => {
  return (
    <Row className="full-row-tall" align="stretch">
      {standardSections.map((t: SectionTypes, i) => {
        return (
          <Col
            key={`sign-up-subscriptions-${i}`}
            xs={24}
            sm={24}
            md={24}
            lg={7}
            xl={7}
            className="flex card-margin"
          >
            <Card
              title={t ? t?.charAt(0).toUpperCase() + t?.slice(1) : undefined}
              className=""
            >
              <Space direction="vertical">
                <Space>
                  <Tag
                    icon={
                      <Tooltip title={"Subscription Level Required"}>
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    }
                  >
                    {sectionTiers[t].charAt(0).toUpperCase() +
                      sectionTiers[t].slice(1)}
                  </Tag>
                  <Tag
                    icon={
                      <Tooltip title={"Building Phase"}>
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    }
                  >
                    {sectionPhases[t]}
                  </Tag>
                </Space>
                {sectionExplainers[t]}
                <Divider />
                <p>Outcomes</p>
                <Timeline items={sectionOutcomes[t]} />
              </Space>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};
