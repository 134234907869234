import React from "react";
import { Hero } from "../components/Hero";
import { BuildingBlocksTable } from "../components/BuildingBlocksTable";
import { defaultBuildPlan } from "../static/DefaultBuildPlan";

export const BuildPreview = () => {
  return (
    <React.Fragment>
      <Hero
        title="Starter Building Blocks"
        explainer="Stop Second Guessing How to Build Your Customer Success Program"
        buttons={[
          {
            type: "primary",
            size: "large",
            text: "Create Account",
            to: "/signup",
          },
        ]}
      />
      <BuildingBlocksTable
        userUID="preview"
        buildPlan={defaultBuildPlan}
        subscription={"starter"}
        page="preview"
      />
    </React.Fragment>
  );
};
