import { TimeLineItemProps } from "antd/es/timeline/TimelineItem";

export const starterTier = [
  "Free Forever",
  "Essential Building Blocks",
  "2 Pillars",
  "17 Tasks with Templates",
  "Building Blocks Progress Reporting",
];

export const starterTierOutcome: TimeLineItemProps[] = [
  {
    children: "Month 1: Foundational Building Blocks Complete",
    color: "#3c8618", // green
  },
  {
    children: "Months 2-4: Attempt Efficiency Building Blocks without Guidance",
    color: "#aa9514", // yellow
  },
  {
    children: "Months 4-6: Attempt Enterprise Building Blocks without Guidance",
    color: "#aa9514",
  },
  {
    children: "Ongoing: No Guidance on Continuous Program Improvement",
    color: "#a61d24", // red
  },
];

export const standardTier = [
  "$5k One Time Fee",
  "Essential, Enterprise, and Efficiency Building Blocks",
  "6 Pillars",
  "45 Tasks with Templates",
  "Access to a Mellasa Consultant via Email for 1 Month",
];

export const standardTierOutcome: TimeLineItemProps[] = [
  {
    children: "Month 1: Foundational Building Blocks Complete",
    color: "#3c8618",
  },
  {
    children: "Month 2: Efficiency Building Blocks Complete",
    color: "#3c8618",
  },
  {
    children: "Month 3: Enterprise Bulding Blocks Complete",
    color: "#3c8618",
  },
  {
    children:
      "Ongoing: Playbooks Provide Self-Guided Exercises for Continuous Improvement",
    color: "#aa9514",
  },
];

export const supportedTier = [
  "$5k per Month",
  "Essential, Enterprise, and Efficiency Building Blocks",
  "6 Pillars",
  "45 Tasks with Templates",
  "Weekly Coaching Corner with a Mellasa Consultant",
  "Not Yet Available - Email hey@mellasa.com to Join Waitlist",
];

export const supportedTierOutcome: TimeLineItemProps[] = [
  {
    children: "Month 1: Foundational Building Blocks Complete",
    color: "#3c8618",
  },
  {
    children: "Month 2: Efficiency Building Blocks Complete",
    color: "#3c8618",
  },
  {
    children: "Month 3: Enterprise Bulding Blocks Complete",
    color: "#3c8618",
  },
  {
    children:
      "Ongoing: Consultant and Playbooks Provide Guided Exercises for Continuous Improvement",
    color: "#3c8618",
  },
];

export const maxSubscription = [
  "Your Subscription is The Highest Tier",
  "Email hey@mellasa.com with Feature Requests",
];
